import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import moment from "moment-timezone";
import { useLocation } from "react-router-dom";

import useHttp from "../../hooks/useHttp";
import useLoading from "../../hooks/useLoading";
import useSnackbar from "../../hooks/useSnackbar";

import { makeData } from "../Table/dataTest";

import { Button, Divider, Grid, Typography, colors } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

import { fuzzyFilter, enumFilter } from "../Table/misc";
import { ActionCell } from "../Table/controls";

import LocalTable from "../Table/LocalTable";
import DeleteDialog from "../Dialogs/DeleteDialog";
import CreateEditDeviceDialog from "./CreateEditDeviceDialog";

const Devices = () => {
  const theme = useTheme();

  const location = useLocation();
  const { get, post } = useHttp();
  const { loading, setLoading } = useLoading();
  const { openSnackbar } = useSnackbar();

  const [editingDevice, setEditingDevice] = useState();
  const [showEditDeviceDialog, setShowEditDeviceDialog] = useState(false);

  const [deviceToDelete, setDeviceToDelete] = useState();
  const [showDeleteDeviceDialog, setShowDeleteDeviceDialog] = useState(false);

  const [allVendors, setAllVendors] = useState([]);
  const [allLocations, setAllLocations] = useState([]);

  // const [data, setData] = useState(() => makeData(50, "device"));
  const [data, setData] = useState([]);
  const [refetch, setRefetch] = useState(false);

  const searchParams = location.search;

  const getVendors = async () => {
    setLoading(true);
    const res = await get("/getVendors");
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setAllVendors(res.data.vendors);
    }
    setLoading(false);
  };

  const getLocations = async () => {
    setLoading(true);
    const res = await get("/getLocations");
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setAllLocations(res.data.locations);
    }
    setLoading(false);
  };

  const getDevices = async () => {
    const query = new URLSearchParams(searchParams);

    setLoading(true);
    const res = await get("/getPaginatedDevices", {
      global: query.get("global"),
      filter: query.get("filter"),
      sort: query.get("sort"),
      page: query.get("page"),
      limit: query.get("limit"),
    });
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setData(res.data.deviceResult);
    }
    setLoading(false);
  };

  const handleCreateDevice = async (newDevice) => {
    setLoading(true);
    const res = await post("/createDevice", { newDevice });
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setShowEditDeviceDialog(false);
      setRefetch((prev) => !prev);
    }

    openSnackbar({
      open: true,
      message: isSuccessful
        ? "Succesfully created device"
        : "Failed to create device",
      variant: "alert",
      alert: {
        color: isSuccessful ? "success" : "error",
      },
      close: false,
    });
    setLoading(false);
  };

  const handleEditDevice = async (newDevice) => {
    setLoading(true);
    const res = await post("/updateDevice", {
      newDevice,
      _id: editingDevice._id,
    });
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setShowEditDeviceDialog(false);
      setRefetch((prev) => !prev);
    }

    openSnackbar({
      open: true,
      message: isSuccessful
        ? "Succesfully updated device details"
        : "Failed to update device details",
      variant: "alert",
      alert: {
        color: isSuccessful ? "success" : "error",
      },
      close: false,
    });
    setLoading(false);
  };

  const handleDeleteDevice = async () => {
    setLoading(true);

    const res = await post("/deleteDevice", {
      _id: deviceToDelete._id,
    });
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setShowDeleteDeviceDialog(false);
      setRefetch((prev) => !prev);
    }

    openSnackbar({
      open: true,
      message: isSuccessful
        ? "Succesfully deleted device"
        : "Failed to delete device",
      variant: "alert",
      alert: {
        color: isSuccessful ? "success" : "error",
      },
      close: false,
    });
    setLoading(false);
  };

  useEffect(() => {
    getDevices();
  }, [refetch, searchParams]);

  useEffect(() => {
    getVendors();
    getLocations();
  }, []);

  // **For testing purposes using dataTest
  // useEffect(() => {
  //   if (!_.isEmpty(data)) {
  //     setAllVendors(data.map((device) => device.vendor));
  //     setAllLocations(data.map((device) => device.location));
  //   }
  // }, [data]);

  const columns = useMemo(() => {
    return [
      {
        header: "Actions",
        size: 50,
        enableHiding: false,
        sticky: "left",
        cell: ({ row }) => {
          const device = row.original;

          return ActionCell({
            row,
            theme,
            handleEdit: () => {
              setEditingDevice(device);
              setShowEditDeviceDialog(true);
            },
            handleDelete: () => {
              setDeviceToDelete(device);
              setShowDeleteDeviceDialog(true);
            },
          });
        },
      },
      {
        header: "Serial Number",
        minSize: 150,
        enableHiding: false,
        accessorKey: "serialNumber",
        filterFn: "includesString",
      },
      {
        id: "type",
        header: "Type",
        minSize: 150,
        accessorFn: (row) => _.get(row, "type", "").toUpperCase() || "Unknown",
        isEnum: true,
        filterFn: enumFilter,
      },
      {
        id: "active",
        header: "Status",
        minSize: 150,
        accessorFn: (row) =>
          _.get(row, "active", false) ? "Active" : "Deactivated",
        isEnum: true,
        filterFn: enumFilter,
      },
      {
        id: "vendor",
        header: "Vendor",
        minSize: 150,
        accessorKey: "vendor.name",
        filterFn: "includesString",
      },
      {
        id: "location",
        header: "Location",
        minSize: 150,
        accessorFn: (row) =>
          `${row?.location?.name} (${row?.location?.collectorCode})`,
        filterFn: "includesString",
      },
    ];
  }, []);

  return (
    <>
      <Grid
        className="w-full overflow-hidden rounded-lg"
        style={{
          margin: theme.spacing(2),
          padding: theme.spacing(3),
          backgroundColor: colors.grey[100],
        }}
      >
        <Typography variant="h4">Devices</Typography>
        <Divider
          style={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
          }}
        />
        <LocalTable
          name="devices-table"
          data={_.get(data, "devices", [])}
          columns={columns}
          manualPagination={_.omit(data, ["devices"])}
        >
          <Button
            variant="contained"
            onClick={() => {
              setEditingDevice();
              setShowEditDeviceDialog(true);
            }}
            disabled={loading}
            sx={{ width: "max-content" }}
          >
            <Add style={{ marginRight: theme.spacing(1) }} />
            Create Device
          </Button>
        </LocalTable>
      </Grid>
      <DeleteDialog
        open={showDeleteDeviceDialog}
        handleClose={() => setShowDeleteDeviceDialog(false)}
        handleDelete={handleDeleteDevice}
        text={`Are you sure you want to delete device, ${_.get(
          deviceToDelete,
          "serialNumber",
          ""
        )}?`}
      />
      <CreateEditDeviceDialog
        open={showEditDeviceDialog}
        device={editingDevice}
        setOpen={setShowEditDeviceDialog}
        handleSubmit={editingDevice ? handleEditDevice : handleCreateDevice}
        vendorOptions={allVendors}
        locationOptions={allLocations}
      />
    </>
  );
};

export default Devices;
