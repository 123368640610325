import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";

import useHttp from "../../hooks/useHttp";
import useLoading from "../../hooks/useLoading";
import useSnackbar from "../../hooks/useSnackbar";

import { Button, Divider, Grid, Typography, colors } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

import { ActionCell } from "../Table/controls";

import LocalTable from "../Table/LocalTable";
import DeleteDialog from "../Dialogs/DeleteDialog";
import CreateEditDepositGroupDialog from "./CreateEditDepositGroupDialog";

const DepositGroups = () => {
  const theme = useTheme();
  const { get, post } = useHttp();
  const { loading, setLoading } = useLoading();
  const { openSnackbar } = useSnackbar();

  const [editingDepositGroup, setEditingDepositGroup] = useState();
  const [showEditDepositGroupDialog, setShowEditDepositGroupDialog] =
    useState(false);

  const [depositGroupToDelete, setDepositGroupToDelete] = useState();
  const [showDeleteDepositGroupDialog, setShowDeleteDepositGroupDialog] =
    useState(false);

  const [allDepositGroups, setAllDepositGroups] = useState([]);

  // const [data, setData] = useState([]);
  const [refetch, setRefetch] = useState(false);

  const getDepositGroups = async () => {
    setLoading(true);
    const res = await get("/getDepositGroups");
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setAllDepositGroups(res.data.depositGroups);
    }
    setLoading(false);
  };

  const handleCreateDepositGroup = async (newDepositGroup) => {
    setLoading(true);
    const res = await post("/createDepositGroup", { newDepositGroup });
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setShowEditDepositGroupDialog(false);
      setRefetch((prev) => !prev);
    }

    let message = "Succesfully created deposit group";
    if (res.status == 409) {
      message = "Failed to create deposit group. Duplicate name";
    } else if (!isSuccessful) {
      message = "Failed to create deposit group";
    }

    openSnackbar({
      open: true,
      message,
      variant: "alert",
      alert: {
        color: isSuccessful ? "success" : "error",
      },
      close: false,
    });
    setLoading(false);
  };

  const handleEditDepositGroup = async (newDepositGroup) => {
    setLoading(true);
    const res = await post("/updateDepositGroup", {
      newDepositGroup,
      _id: editingDepositGroup._id,
    });
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setShowEditDepositGroupDialog(false);
      setRefetch((prev) => !prev);
    }

    let message = "Succesfully created deposit group";
    if (res.status == 409) {
      message = "Failed to create deposit group. Duplicate name";
    } else if (!isSuccessful) {
      message = "Failed to create deposit group";
    }

    openSnackbar({
      open: true,
      message,
      variant: "alert",
      alert: {
        color: isSuccessful ? "success" : "error",
      },
      close: false,
    });
    setLoading(false);
  };

  const handleDeleteDepositGroup = async () => {
    setLoading(true);

    const res = await post("/deleteDepositGroup", {
      _id: depositGroupToDelete._id,
    });
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setShowDeleteDepositGroupDialog(false);
      setRefetch((prev) => !prev);
    }

    openSnackbar({
      open: true,
      message: isSuccessful
        ? "Succesfully deleted deposit group"
        : "Failed to delete deposit group",
      variant: "alert",
      alert: {
        color: isSuccessful ? "success" : "error",
      },
      close: false,
    });
    setLoading(false);
  };

  useEffect(() => {
    getDepositGroups();
  }, [refetch]);

  const columns = useMemo(() => {
    return [
      {
        header: "Actions",
        size: 50,
        enableHiding: false,
        sticky: "left",
        cell: ({ row }) => {
          const depositGroup = row.original;

          return ActionCell({
            row,
            theme,
            handleEdit: () => {
              setEditingDepositGroup(depositGroup);
              setShowEditDepositGroupDialog(true);
            },
            handleDelete: () => {
              setDepositGroupToDelete(depositGroup);
              setShowDeleteDepositGroupDialog(true);
            },
          });
        },
      },
      {
        header: "Name",
        minSize: 150,
        enableHiding: false,
        accessorKey: "name",
        filterFn: "includesString",
      },
      {
        header: "Material",
        minSize: 150,
        accessorKey: "material",
      },
      {
        header: "Value",
        minSize: 150,
        accessorKey: "value",
      },
      {
        header: "Super Group",
        minSize: 150,
        accessorKey: "superGroup",
      },
    ];
  }, []);

  return (
    <>
      <Grid
        className="w-full overflow-hidden rounded-lg"
        style={{
          margin: theme.spacing(2),
          padding: theme.spacing(3),
          backgroundColor: colors.grey[100],
        }}
      >
        <Typography variant="h4">Deposit Groups</Typography>
        <Divider
          style={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
          }}
        />
        <LocalTable
          name="deposit-groups-table"
          data={allDepositGroups}
          columns={columns}
        >
          <Button
            variant="contained"
            onClick={() => {
              setEditingDepositGroup();
              setShowEditDepositGroupDialog(true);
            }}
            disabled={loading}
            sx={{ width: "max-content" }}
          >
            <Add style={{ marginRight: theme.spacing(1) }} />
            Create Deposit Group
          </Button>
        </LocalTable>
      </Grid>
      <DeleteDialog
        open={showDeleteDepositGroupDialog}
        handleClose={() => setShowDeleteDepositGroupDialog(false)}
        handleDelete={handleDeleteDepositGroup}
        text={`Are you sure you want to delete deposit group, ${_.get(
          depositGroupToDelete,
          "name",
          ""
        )}?`}
      />
      <CreateEditDepositGroupDialog
        open={showEditDepositGroupDialog}
        depositGroup={editingDepositGroup}
        setOpen={setShowEditDepositGroupDialog}
        handleSubmit={
          editingDepositGroup
            ? handleEditDepositGroup
            : handleCreateDepositGroup
        }
      />
    </>
  );
};

export default DepositGroups;
