import React, { useEffect } from "react";
import _ from "lodash";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";
import { isValidEmail } from "../../utils/misc";
import useAuth from "../../hooks/useAuth";

import {
  TextField,
  Grid,
  MenuItem,
  Stack,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";

import DefaultDialog from "../Dialogs/DefaultDialog";
import { Section } from "../Dialogs/controls";
import { ROLES_ARR } from "../../constants";

const CreateEditUserDialog = ({
  open,
  setOpen,
  user,
  handleSubmit,
  vendorOptions = [],
}) => {
  const theme = useTheme();
  const textfieldStyles = { height: "1.2rem" };

  const { authenticated } = useAuth();
  const isSystemAdmin = authenticated.roles.includes("System Administrator");

  const isCreate = _.isNil(user);

  const schema = Yup.object().shape({
    name: Yup.object({
      first: Yup.string().required("First name is required"),
      last: Yup.string().required("Last name is required"),
    }),
    email: Yup.string()
      .email("Invalid email")
      .test("validEmail", "Invalid email", (value) => {
        if (!value) return true;
        return isValidEmail(value);
      })
      .required("Email is required"),
    vendor: Yup.string().nullable(),
    roles: Yup.array().min(1, "At least one role is required"), // Ensure the array has at least one element
    disabled: Yup.boolean(),
  });

  const getInitialValues = (user) => {
    let initVals = {
      name: {
        first: "",
        last: "",
      },
      email: "",
      vendor: null,
      roles: [],
      disabled: false,
    };

    if (!_.isNil(user)) {
      initVals = _.mergeWith({}, initVals, user, (objVal, srcVal) => {
        if (_.isObject(srcVal)) {
          return _.get(srcVal, "_id");
        }
      });
    }

    return initVals;
  };

  const formik = useFormik({
    initialValues: getInitialValues(user),
    validationSchema: schema,
    onSubmit: (values, { setSubmitting }) => {
      try {
        handleSubmit(values);
        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    },
    enableReinitialize: true,
  });

  const {
    values,
    errors,
    touched,
    handleSubmit: handleSubmitFormik,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    resetForm,
  } = formik;

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  return (
    <DefaultDialog
      open={open}
      title={`${isCreate ? "Create" : "Edit"} User`}
      iconType={isCreate ? "add" : "edit"}
      handleClose={() => setOpen(false)}
      handleConfirm={handleSubmitFormik}
      confirmButtonName={isCreate ? "Create" : "Update"}
      closeButtonName="Cancel"
      disableConfirm={isSubmitting}
    >
      <FormikProvider value={formik}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Section title="Name" theme={theme}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    {...getFieldProps("name.first")}
                    error={Boolean(touched.name?.first && errors.name?.first)}
                    helperText={touched.name?.first && errors.name?.first}
                    label="First"
                    InputLabelProps={{
                      style: textfieldStyles,
                      shrink: !_.isEmpty(values.name?.first),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    {...getFieldProps("name.last")}
                    error={Boolean(touched.name?.last && errors.name?.last)}
                    helperText={touched.name?.last && errors.name?.last}
                    label="Last"
                    InputLabelProps={{
                      style: textfieldStyles,
                      shrink: !_.isEmpty(values.name?.last),
                    }}
                  />
                </Grid>
              </Grid>
            </Section>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              {...getFieldProps("email")}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              label="Email"
              InputLabelProps={{
                style: textfieldStyles,
                shrink: !_.isEmpty(values.email),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              fullWidth
              {...getFieldProps("vendor")}
              error={Boolean(touched.vendor && errors.vendor)}
              helperText={touched.vendor && errors.vendor}
              label="Vendor"
              InputLabelProps={{
                style: textfieldStyles,
                shrink: !_.isEmpty(values.vendor),
              }}
            >
              {_.isEmpty(vendorOptions) ? (
                <MenuItem disabled={true}>No vendors</MenuItem>
              ) : (
                [
                  ...(isSystemAdmin // Adds the none option when user is found to be a system admin
                    ? [
                        <MenuItem
                          key="none"
                          value={null}
                          sx={{ color: theme.palette.text.secondary }}
                        >
                          <em>None</em>
                        </MenuItem>,
                      ]
                    : []),
                  vendorOptions.map((vendor) => (
                    <MenuItem value={vendor._id} key={vendor._id}>
                      {_.get(vendor, "name", "Unknown")}
                    </MenuItem>
                  )),
                ]
              )}
            </TextField>
          </Grid>
          {isSystemAdmin && (
            <Grid item xs={12}>
              <TextField
                required
                select
                fullWidth
                {...getFieldProps("roles")}
                error={Boolean(touched.roles && errors.roles)}
                helperText={touched.roles && errors.roles}
                label="Roles"
                SelectProps={{ multiple: true }}
                InputLabelProps={{
                  style: textfieldStyles,
                  shrink: !_.isEmpty(values.roles),
                }}
              >
                {ROLES_ARR.map((role) => (
                  <MenuItem value={role} key={role}>
                    {role}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          <Grid item xs={12}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Switch
                checked={!values.disabled}
                onChange={(event) =>
                  setFieldValue("disabled", !event.target.checked)
                }
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography>Enabled</Typography>
            </Stack>
          </Grid>
        </Grid>
      </FormikProvider>
    </DefaultDialog>
  );
};

export default CreateEditUserDialog;
