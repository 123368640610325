import React from "react";
import { Box, LinearProgress } from "@mui/material";

const LinearLoading = ({ show }) => {
  return (
    <Box
      sx={{
        width: "100%",
        opacity: show ? "1" : "0",
        transition: "opacity 0.5s ease",
      }}
    >
      <LinearProgress />
    </Box>
  );
};

export default LinearLoading;
