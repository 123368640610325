import React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
} from "@mui/material";

import ReactJson from "react-json-view";

import useSnackbar from "../../hooks/useSnackbar";

function JSONDialog({ open, json, onClose }) {
  const { openSnackbar } = useSnackbar();

  const copyJSON = () => {
    navigator.clipboard
      .writeText(JSON.stringify(json))
      .then(() => {
        openSnackbar({
          open: true,
          message: "Payload copied to clipboard",
          variant: "alert",
          alert: {
            color: "success",
          },
          close: false,
        });
      })
      .catch((err) => {
        openSnackbar({
          open: true,
          message: "Error copying to clipboard",
          variant: "alert",
          alert: {
            color: "error",
          },
          close: false,
        });
      });
  };

  return (
    <Dialog open={open} fullWidth onClose={onClose}>
      <DialogTitle>Viewing JSON</DialogTitle>
      <DialogContent>
        <ReactJson
          theme="bright:inverted"
          collapsed={2}
          indentWidth={2}
          src={json || {}}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={copyJSON}>
          Copy JSON
        </Button>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default JSONDialog;
