import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";

import {
  TextField,
  Grid,
  // Autocomplete,
  MenuItem,
  Stack,
  Switch,
  Typography,
  Collapse,
} from "@mui/material";

import DefaultDialog from "../Dialogs/DefaultDialog";
import { TagInput } from "../Dialogs/controls";
import { API_FUNCTIONALITIES, IPV4_REGEX } from "../../constants";
import VAutocompleteLazyWrapper from "../Autocomplete/VAutocompleteLazyWrapper";
// import VirtualizedAutocomplete from "../Autocomplete/VirtualizedAutocomplete";

const CreateEditKeyDialog = ({
  open,
  setOpen,
  apiKey,
  handleSubmit,
  vendorOptions = [],
  // deviceOptions = [],
}) => {
  const isCreate = _.isNil(apiKey);

  const schema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    vendor: Yup.string().required("Vendor is required"),
    active: Yup.boolean(),
    allowedDevices: Yup.array(),
    allowedFunctionalities: Yup.array(),
    allowedIPs: Yup.array(),
  });

  const getInitialValues = (apiKey) => {
    let initVals = {
      name: "",
      vendor: "",
      active: true,
      allowedDevices: [],
      allowedFunctionalities: [],
      allowedIPs: [],
    };

    if (!_.isNil(apiKey)) {
      initVals = _.mergeWith({}, initVals, apiKey, (objVal, srcVal) => {
        if (_.isArray(srcVal)) {
          return srcVal; // needed for allowedDevices
        } else if (_.isObject(srcVal)) {
          return _.get(srcVal, "_id");
        }
      });
    }

    if (vendorOptions.length === 1)
      initVals.vendor = _.get(vendorOptions, ["0", "_id"]);

    return initVals;
  };

  const formik = useFormik({
    initialValues: getInitialValues(apiKey),
    validationSchema: schema,
    onSubmit: (values, { setSubmitting }) => {
      try {
        const keyToSubmit = _.cloneDeep(values);
        const { allowedDevices } = keyToSubmit;

        _.set(
          keyToSubmit,
          "allowedDevices",
          allowedDevices.map((dvc) => dvc._id)
        );

        handleSubmit(keyToSubmit);
        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    },
    enableReinitialize: true,
  });

  const {
    values,
    errors,
    touched,
    handleSubmit: handleSubmitFormik,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;

  // const filteredDevices = useMemo(() => {
  //   const selectedVendor = values.vendor;
  //   if (_.isEmpty(selectedVendor)) {
  //     return deviceOptions;
  //   }

  //   return _.filter(deviceOptions, ["vendor._id", selectedVendor]);
  // }, [values.vendor, deviceOptions]);

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  /* Clears the allowed devices field when it finds that one of the devices does belong to the selected vendor*/
  // useEffect(() => {
  //   const selectedDevices = values.allowedDevices;

  //   if (!_.isEmpty(selectedDevices)) {
  //     const selectedIds = selectedDevices.map((dvc) => dvc._id);
  //     const filteredIds = filteredDevices.map((dvc) => dvc._id);
  //     const diff = _.difference(selectedIds, filteredIds);

  //     if (diff.length > 0) {
  //       setFieldTouched("allowedDevices");
  //       setFieldValue("allowedDevices", []);
  //     }
  //   }
  // }, [filteredDevices, values.allowedDevices]);

  return (
    <DefaultDialog
      open={open}
      title={`${isCreate ? "Create" : "Edit"} API Key`}
      iconType={isCreate ? "add" : "edit"}
      handleClose={() => setOpen(false)}
      handleConfirm={handleSubmitFormik}
      confirmButtonName={isCreate ? "Create" : "Update"}
      closeButtonName="Cancel"
      disableConfirm={isSubmitting}
    >
      <FormikProvider value={formik}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              {...getFieldProps("name")}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
              label="Name"
              InputLabelProps={{
                style: {
                  height: "1.2em",
                },
                shrink: !_.isEmpty(values.name),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              select
              fullWidth
              data-testid="api-key-vendor-select"
              {...getFieldProps("vendor")}
              error={Boolean(touched.vendor && errors.vendor)}
              helperText={touched.vendor && errors.vendor}
              label="Vendor"
              InputLabelProps={{
                style: {
                  height: "1.2em",
                },
                shrink: !_.isEmpty(values.vendor),
              }}
              onChange={(e) => {
                const vendor = e.target.value;
                setFieldValue("vendor", vendor);
                setFieldValue("allowedDevices", []);
              }}
            >
              {_.isEmpty(vendorOptions) ? (
                <MenuItem disabled={true}>No vendors</MenuItem>
              ) : (
                vendorOptions.map((vendor) => (
                  <MenuItem value={vendor._id} key={vendor._id}>
                    {_.get(vendor, "name", "Unknown")}
                  </MenuItem>
                ))
              )}
            </TextField>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              padding: _.isEmpty(values.vendor) ? "0 !important" : "inherit",
            }}
          >
            <Collapse in={!_.isEmpty(values.vendor)}>
              <VAutocompleteLazyWrapper
                multiple
                id="api-key-devices-autocomplete"
                data-testid="api-key-devices-autocomplete"
                filterSelectedOptions
                disableCloseOnSelect
                value={values.allowedDevices}
                noOptionsText="No devices for selected vendor"
                // options={filteredDevices}
                onChange={(event, value) =>
                  setFieldValue("allowedDevices", value)
                }
                getOptionLabel={(option) =>
                  `${option.serialNumber} (${_.upperCase(option.type)})`
                }
                isOptionEqualToValue={(option, value) =>
                  _.get(option, "_id", 1) === _.get(value, "_id", 2)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Authorized Devices"
                    helperText="Leave empty to authorize all devices for selected vendor"
                    InputLabelProps={{
                      style: {
                        height: "1.2em",
                      },
                    }}
                  />
                )}
                endpoint="/getDeviceOptions" // uncomment below if you want to do lazy loading
                resultsKey="devices"
                endpointQuery={{ vendor: values.vendor }}
              />
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <TagInput
              label="Allowed IPs"
              helperText="Leave empty to authorize all IPs for selected vendor"
              value={values.allowedIPs}
              setValue={(ips) => setFieldValue("allowedIPs", ips)}
              regex={IPV4_REGEX}
              regexErrorText="Please provide a valid IP address"
              reset={open}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              data-testid="api-key-functionalites-select"
              {...getFieldProps("allowedFunctionalities")}
              helperText="Leave empty to authorize all functionalities for selected vendor"
              label="Allowed Functionalities"
              SelectProps={{ multiple: true }}
              InputLabelProps={{
                style: {
                  height: "1.2em",
                },
                shrink: !_.isEmpty(values.allowedFunctionalities),
              }}
            >
              {API_FUNCTIONALITIES.map((func) => (
                <MenuItem value={func} key={func}>
                  {func}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Switch
                checked={values.active}
                onChange={(event) =>
                  setFieldValue("active", event.target.checked)
                }
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography>Active</Typography>
            </Stack>
          </Grid>
        </Grid>
      </FormikProvider>
    </DefaultDialog>
  );
};

export default CreateEditKeyDialog;
