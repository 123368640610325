import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { areArrsEqualExclOrder } from "../../utils/misc";

import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
} from "@tanstack/react-table";

import {
  TableContainer,
  Box,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TextField,
  Stack,
  Typography,
  MenuItem,
  Checkbox,
  ListItemText,
  IconButton,
  Chip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { fuzzyFilter } from "./misc";
import { ArrowDropDown, ArrowDropUp, FilterList } from "@mui/icons-material";
import { TablePaginationActions } from "./controls";

const LocalTable = ({
  name = "",
  data,
  columns,
  children,
  initSorting = [],
  initColVisibility = {},
  manualPagination,
}) => {
  const theme = useTheme();

  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = location.search;
  const sessionStorageKey = `${name}-columns`;

  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState(initColVisibility);
  const [sorting, setSorting] = useState(initSorting);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [hoveredCol, setHoveredCol] = useState();

  const getWidthStyles = (header) => {
    return {
      width: `${header.getSize()}px`,
      minWidth: `${header.column.columnDef.minSize}px`,
      maxWidth: `${header.column.columnDef.maxSize}px`,
    };
  };

  const getStickyStyles = (column, isSticky) => {
    return {
      left: isSticky === "left" ? `${column.getStart("left")}px` : undefined,
      right: isSticky === "right" ? `${column.getAfter("right")}px` : undefined,
      // opacity: isSticky ? 0.9 : 1,
      position: isSticky ? "sticky" : "relative",
      zIndex: isSticky ? 1 : 0,
    };
  };

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      columnFilters,
      columnVisibility,
      sorting,
      pagination,
    },
    autoResetPageIndex: false, //turn off auto reset of pageIndex (IMPORTANT)
    globalFilterFn: _.isEmpty(manualPagination)
      ? fuzzyFilter //apply fuzzy filter to the global filter (most common use case for fuzzy filter)
      : "includesString",
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    ...(_.isEmpty(manualPagination)
      ? {
          getFilteredRowModel: getFilteredRowModel(), // client side filtering
          getSortedRowModel: getSortedRowModel(), // client side sorting
          getPaginationRowModel: getPaginationRowModel(), // client side pagination
        }
      : {
          manualFiltering: true,
          manualSorting: true,
          manualPagination: true,
          rowCount: _.get(manualPagination, "rowCount"),
        }),
  });

  const handleUpdateSearchParams = (newParams) => {
    const currentParams = new URLSearchParams(searchParams);
    Object.keys(newParams).forEach((key) => {
      const value = newParams[key];
      if (Array.isArray(value)) {
        currentParams.delete(key);
        value.forEach((val) => {
          currentParams.append(key, val);
        });
      } else if (value) {
        currentParams.set(key, value);
      } else {
        currentParams.delete(key);
      }
    });
    navigate({ search: currentParams.toString() });
  };

  const handleChangeVisibility = (event) => {
    const value = _.get(event, "target.value", []);

    // Determine if the option was added or removed
    const selectedColId = _.xor(visibleColIds, value)[0];
    const isAdded = value.includes(selectedColId);

    table.getColumn(selectedColId).toggleVisibility(isAdded);
  };

  const visibleColIds = useMemo(() => {
    if (_.isNil(table) || _.isEmpty(columnVisibility)) return [];

    return _.keys(_.pickBy(columnVisibility, Boolean));
  }, [columnVisibility, table]);

  useEffect(() => {
    const colVis = table.getAllColumns().reduce((accum, column) => {
      if (column.getCanHide()) {
        _.set(accum, column.id, column.getIsVisible());
      }
      return accum;
    }, {});

    let mergedColVis = _.merge({}, colVis, columnVisibility);
    const savedColVis = sessionStorage.getItem(sessionStorageKey);
    if (savedColVis) {
      const sessStorColVis = _.merge({}, mergedColVis, JSON.parse(savedColVis));

      // used for when the columns change to prevent crashes
      if (areArrsEqualExclOrder(_.keys(sessStorColVis), _.keys(mergedColVis)))
        mergedColVis = sessStorColVis;
    }

    setColumnVisibility(mergedColVis);
  }, [table]);

  useEffect(() => {
    if (!_.isEmpty(columnVisibility)) {
      sessionStorage.setItem(
        sessionStorageKey,
        JSON.stringify(columnVisibility)
      );
    }
  }, [columnVisibility]);

  // Updates the search params when the global filter changes
  useEffect(() => {
    handleUpdateSearchParams({
      global: !_.isEmpty(globalFilter) && globalFilter,
    });
  }, [globalFilter]);

  // Updates the search params when column filters change
  useEffect(() => {
    let colFilterSearchParams;
    if (!_.isEmpty(columnFilters)) {
      const colFiltersObj = columnFilters
        .filter(({ value }) => !_.isEmpty(value))
        .reduce((accum, { id, value }) => {
          if (
            !_.isEmpty(value) &&
            !(_.isArray(value) && _.every(value, _.isEmpty))
          ) {
            _.set(accum, id, value);
          }
          return accum;
        }, {});

      colFilterSearchParams = encodeURIComponent(JSON.stringify(colFiltersObj));
    }

    handleUpdateSearchParams({ filter: colFilterSearchParams });
  }, [columnFilters]);

  // Updates the search params when the sorting changes
  useEffect(() => {
    let sortSearchParams;
    if (!_.isEmpty(sorting)) {
      sortSearchParams = sorting
        .map(({ id, desc }) => `${id}:${desc ? "desc" : "asc"}`)
        .join(",");
    }

    handleUpdateSearchParams({ sort: sortSearchParams });
  }, [sorting]);

  // **NEEDED TO ADJUST THE TABLE STATE BASED OFF OF THE URL SEARCH PARAMS
  // (I.E. WHEN YOU LOAD THE PAGE WITH THE URL ALREADY HAVING SEARCH PARAMS)
  useEffect(() => {
    if (!_.isEmpty(searchParams)) {
      const query = new URLSearchParams(searchParams);

      // Global filtering
      let globalStr = _.clone(globalFilter);
      if (query.has("global")) globalStr = query.get("global");
      if (!_.isEqual(globalStr, globalFilter)) setGlobalFilter(globalStr);

      // Column filtering
      let colFiltersArr = _.cloneDeep(columnFilters);
      if (query.has("filter")) {
        const colFiltersObj = JSON.parse(
          decodeURIComponent(query.get("filter"))
        );
        colFiltersArr = Object.entries(colFiltersObj).map(([id, value]) => ({
          id,
          value,
        }));
      }
      if (!_.isEqual(colFiltersArr, columnFilters))
        setColumnFilters(colFiltersArr);

      // Sorting
      let sortingArr = _.cloneDeep(sorting);
      if (query.has("sort")) {
        sortingArr = query
          .get("sort")
          .split(",")
          .map((sortStr) => {
            const [id, desc] = sortStr.split(":");
            return { id, desc: desc === "desc" };
          });
      }
      if (!_.isEqual(sortingArr, sorting)) setSorting(sortingArr);

      // Pagination
      let paginationObj = _.cloneDeep(pagination);
      if (query.has("page"))
        _.set(paginationObj, "pageIndex", parseInt(query.get("page")) - 1);
      if (query.has("limit"))
        _.set(paginationObj, "pageSize", parseInt(query.get("limit")));
      if (!_.isEqual(paginationObj, pagination)) setPagination(paginationObj);
    }
  }, [searchParams, table]);

  const hidableColumns = table
    .getAllColumns()
    .filter((col) => col.getCanHide());

  const allCount = _.get(
    manualPagination,
    "rowCount",
    table.getFilteredRowModel().rows.length
  );

  return (
    <Box className="p-4 overflow-hidden" component={Paper}>
      <Stack
        // flexWrap="wrap"
        useFlexGap
        spacing={{ xs: 2, lg: 4 }}
        direction={{ xs: "col", lg: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "center", lg: "flex-start" }}
        sx={{ mb: 2 }}
      >
        <Stack
          direction="row"
          flexWrap="wrap"
          useFlexGap
          spacing={2}
          alignItems="center"
          justifyContent={{ xs: "center", lg: "start" }}
        >
          {/* <FilterList className="text-3xl" /> */}
          <DebouncedInput
            label="Search"
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(value)}
            placeholder="Search all columns..."
            sx={{ width: 250 }}
          />
          <TextField
            select
            value={visibleColIds}
            onChange={handleChangeVisibility}
            SelectProps={{
              multiple: true,
              renderValue: (selected) =>
                selected
                  .map((colId) => table.getColumn(colId).columnDef.header)
                  .join(", "),
            }}
            label="Visible Columns"
            sx={{ width: 250 }}
          >
            {_.isEmpty(hidableColumns) ? (
              <MenuItem disabled>No hidable columns</MenuItem>
            ) : (
              hidableColumns.map((column) => {
                return (
                  <MenuItem key={column.id} value={column.id}>
                    <Checkbox checked={column.getIsVisible()} />
                    <ListItemText primary={column.columnDef.header} />
                  </MenuItem>
                );
              })
            )}
          </TextField>
          {columnFilters.map((filter) => {
            const { id, value } = filter;
            const col = table.getColumn(id);
            const headerStr = _.get(col, "columnDef.header");

            return (
              <Chip
                label={`${headerStr}: ${value}`}
                color="primary"
                onDelete={() =>
                  setColumnFilters((prev) =>
                    _.filter(prev, (filter) => _.get(filter, "id") !== id)
                  )
                }
              />
            );
          })}
        </Stack>
        {children && (
          <Stack
            direction="row"
            flexWrap="wrap"
            useFlexGap
            spacing={2}
            alignItems={{ xs: "flex-start", lg: "center" }}
            justifyContent={{ xs: "center", lg: "flex-start" }}
            className="lg:mt-2"
          >
            {children}
          </Stack>
        )}
      </Stack>
      {/* {!_.isEmpty(columnFilters) && (
        <Stack
          direction="row"
          flexWrap="wrap"
          useFlexGap
          spacing={1}
          justifyContent={{ xs: "center", lg: "flex-start" }}
          sx={{ my: 2 }}
        >
          {columnFilters.map((filter) => {
            const { id, value } = filter;
            const col = table.getColumn(id);
            const headerStr = _.get(col, "columnDef.header");

            return (
              <Chip
                label={`${headerStr}: ${value}`}
                color="primary"
                onDelete={() =>
                  setColumnFilters((prev) =>
                    _.filter(prev, (filter) => _.get(filter, "id") !== id)
                  )
                }
              />
            );
          })}
        </Stack>
      )} */}
      <TableContainer>
        <Table name={name} id={name}>
          <TableHead className="relative z-20">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header, idx) => {
                  const headerColDef = _.get(header, "column.columnDef");
                  const isSticky = _.get(headerColDef, "sticky");
                  const headerAdditions = _.get(
                    headerColDef,
                    "headerAdditions"
                  );
                  const canSort = header.column.getCanSort();
                  const canFilter = header.column.getCanFilter();

                  const isLast = idx === headerGroup.headers.length - 1;
                  return (
                    <TableCell
                      key={header.id}
                      colSpan={header.colSpan}
                      className={`align-top h-0 ${isSticky && "sticky"}`} // need to set the table cell height (to 0) for child to get 100% of cell height
                      style={{
                        ...getWidthStyles(header),
                        ...getStickyStyles(header.column, isSticky),
                      }}
                      sx={{
                        backgroundColor: theme.palette.grey[200],
                        p: 1,
                      }}
                      onMouseEnter={() => setHoveredCol(header.id)}
                      onMouseLeave={() => setHoveredCol()}
                    >
                      {header.isPlaceholder ? null : (
                        <Stack
                          className="h-full"
                          justifyContent="space-between"
                        >
                          <div
                            className="relative select-none flex align-middle"
                            style={{ cursor: canSort ? "pointer" : "normal" }}
                            onClick={header.column.getToggleSortingHandler()}
                            title={(() => {
                              const nextSortOrder =
                                header.column.getNextSortingOrder();
                              const sortDict = {
                                asc: "Sort ascending",
                                desc: "Sort descending",
                              };
                              return canSort
                                ? _.get(sortDict, nextSortOrder, "Clear sort")
                                : undefined;
                            })()}
                          >
                            <Typography
                              className={`font-medium whitespace-pre-line`}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </Typography>
                            {headerAdditions}
                            {{
                              asc: <ArrowDropUp />,
                              desc: <ArrowDropDown />,
                            }[header.column.getIsSorted().toString()] ?? null}
                          </div>
                          {canFilter && (
                            <div
                              className={`absolute top-full overflow-hidden z-10 
                                  w-full origin-top 
                                  ${isLast ? "right-0" : "left-0"}`}
                              style={{
                                transform:
                                  header.id === hoveredCol
                                    ? "scaleY(1)"
                                    : "scaleY(0)",
                                minWidth: 175,
                                backgroundColor: theme.palette.grey[200],
                                transition: "transform 0.2s ease",
                              }}
                            >
                              <div className="p-3 pt-0">
                                <Filter
                                  column={header.column}
                                  table={table}
                                  manualPagination={manualPagination}
                                  onCloseSelect={() => setHoveredCol()}
                                />
                              </div>
                            </div>
                          )}
                        </Stack>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody className="relative z-10">
            {_.isEmpty(data) ||
            table.getFilteredRowModel().flatRows.length === 0 ? (
              <TableRow>
                <TableCell colSpan="100%" sx={{ p: 4 }}>
                  <Typography className="w-full text-center">
                    No data available.
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              table.getRowModel().rows.map((row) => {
                return (
                  <TableRow key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      const isSticky = _.get(cell, "column.columnDef.sticky");
                      return (
                        <TableCell
                          key={cell.id}
                          className={`bg-white ${isSticky && "sticky"}`}
                          style={{
                            ...getStickyStyles(cell.column, isSticky),
                          }}
                          sx={{ p: 1 }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[
          5,
          10,
          25,
          {
            label: "All",
            value: allCount,
          },
        ]}
        component="div"
        count={allCount}
        rowsPerPage={pagination.pageSize}
        page={pagination.pageIndex}
        slotProps={{
          select: {
            inputProps: { "aria-label": "rows per page" },
            native: true,
          },
        }}
        onPageChange={(__, pageIndex) => {
          table.setPageIndex(pageIndex);

          handleUpdateSearchParams({ page: pageIndex && pageIndex + 1 }); // only want to add search param when page > 1
        }}
        onRowsPerPageChange={(e) => {
          const size = e.target.value ? parseInt(e.target.value) : 10;
          table.setPageSize(size);
          table.setPageIndex(0);

          handleUpdateSearchParams({
            limit: size !== 10 && size,
            page: false,
          }); // want to clear page search param and only add limit when != 10
        }}
        ActionsComponent={TablePaginationActions}
      />
    </Box>
  );
};

export default LocalTable;

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value, debounce]);

  return (
    <TextField
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

function Filter({ column, table, manualPagination, onCloseSelect }) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();
  const columnDef = _.get(column, "columnDef");

  const isNum = _.get(columnDef, "isNum", false);
  const isEnum = _.get(columnDef, "isEnum", false);

  const serverSideEnum = _.get(manualPagination, ["enumFields", column.id], []);
  const isServerSideEnum = !_.isEmpty(serverSideEnum);

  if (typeof firstValue === "number" || isNum) {
    return (
      <div className="flex space-x-2">
        <TextField
          type="number"
          value={columnFilterValue?.[0] ?? ""}
          onChange={(e) => {
            const val = e.target.value;
            column.setFilterValue((old) => [
              _.isEmpty(val) ? null : val,
              _.get(old, "1"),
            ]);
          }}
          placeholder={`Min`}
          className="w-1/2 border rounded"
          inputProps={{ className: "text-sm p-2 bg-white" }}
        />
        <TextField
          type="number"
          value={columnFilterValue?.[1] ?? ""}
          onChange={(e) => {
            const val = e.target.value;
            column.setFilterValue((old) => [
              _.get(old, "0"),
              _.isEmpty(val) ? null : val,
            ]);
          }}
          placeholder={`Max`}
          className="w-1/2 border rounded"
          inputProps={{
            "aria-label": "search",
            className: "text-sm p-2 bg-white",
          }}
        />
      </div>
    );
  } else if (typeof firstValue === "boolean" || isEnum || isServerSideEnum) {
    let filterVals = [];
    if (isServerSideEnum) {
      const getOptionVal = (option, colId) =>
        columnDef.accessorFn
          ? columnDef.accessorFn({ [colId]: option })
          : _.toString(option); // needed for booleans to show up as options in dropdown

      filterVals = serverSideEnum.map((option) =>
        getOptionVal(option, column.id)
      );
    } else {
      const allColVals = table
        .getCoreRowModel()
        .flatRows.map((row) => row.getValue(column.id));

      filterVals = _(allColVals)
        .filter((val) => !_.isNil(val) && val !== "-")
        .map((val) => val.toString())
        .uniq()
        .sort()
        .value();
    }

    return (
      <TextField
        select
        SelectProps={{
          multiple: true,
          native: false,
          displayEmpty: true,
          renderValue: (selected) => {
            const selectedCount = selected.length;
            if (selectedCount === 0)
              return <span className="text-[#bfbfbf]">Filter...</span>;
            else return <span>{selectedCount} selected</span>;
          },
          onClose: onCloseSelect,
        }}
        value={columnFilterValue ?? []}
        onChange={(e) => column.setFilterValue(e.target.value)}
        className=" w-full border rounded"
        inputProps={{
          className: "text-sm p-2 bg-white whitespace-normal",
        }}
      >
        {filterVals.map((val) => (
          <MenuItem key={val} value={val}>
            {val}
          </MenuItem>
        ))}
      </TextField>
    );
  } else {
    return (
      <DebouncedInput
        value={columnFilterValue ?? ""}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`Search...`}
        className="w-full border rounded"
        inputProps={{
          className: "text-sm bg-white p-2",
        }}
      />
    );
  }
}
