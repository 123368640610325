import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import moment from "moment-timezone";
import { useLocation } from "react-router-dom";

import useHttp from "../../hooks/useHttp";
import useLoading from "../../hooks/useLoading";
import useSnackbar from "../../hooks/useSnackbar";

import { makeData } from "../Table/dataTest";
import { getDeviceName } from "../Table/misc";
import { ActionCell } from "../Table/controls";

import { Divider, Grid, Typography, colors } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { fuzzyFilter, enumFilter, getSortVal } from "../Table/misc";

import LocalTable from "../Table/LocalTable";
import { camelCaseToSpaceCase } from "../../utils/misc";
import { Circle, DataObject } from "@mui/icons-material";

import JSONDialog from "../Dialogs/JSONDialog";

const WebhookEvents = () => {
  const theme = useTheme();

  const location = useLocation();
  const { get, post } = useHttp();
  const { loading, setLoading } = useLoading();
  const { openSnackbar } = useSnackbar();

  // const [data, setData] = useState(() => makeData(50, "transaction"));
  const [data, setData] = useState([]);
  const [refetch, setRefetch] = useState(false);

  const [showJSONDialog, setShowJSONDialog] = useState(false);
  const [jsonLoading, setJSONLoading] = useState(false);
  const [json, setJSON] = useState({});

  const openJSONDialog = async (webhookEventId) => {
    setLoading(true);

    const res = await get(`/webhookEvent/${webhookEventId}/payload`);
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setJSON(res.data);
      setShowJSONDialog(true);
    } else {
      openSnackbar({
        open: true,
        message: "An error occurred while loading the payload",
        variant: "alert",
        alert: {
          color: "error",
        },
        close: false,
      });
    }

    setLoading(false);
  };
  
  const searchParams = location.search;

  const getWebhookEvents = async () => {
    const query = new URLSearchParams(searchParams);

    setLoading(true);
    const res = await get("/getWebhookEvents", {
      global: query.get("global"),
      filter: query.get("filter"),
      sort: query.get("sort"),
      page: query.get("page"),
      limit: query.get("limit"),
      timezone: moment.tz.guess(),
    });
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setData(res.data.webhookEventResult);
    }
    setLoading(false);
  };

  useEffect(() => {
    getWebhookEvents();
  }, [refetch, searchParams]);

  const columns = useMemo(() => {
    return [
      {
        header: "Actions",
        size: 50,
        enableHiding: false,
        sticky: "left",
        cell: ({ row }) => {
          const webhookEvent = row.original;

          return ActionCell({
            row,
            theme,
            extrasArr: [
              {
                tooltipMsg: "Payload",
                handleClick: () => {
                  openJSONDialog(webhookEvent._id);
                },
                icon: <DataObject />,
              },
            ],
            hideDelete: true,
            hideEdit: true,
          });
        },
      },
      {
        id: "status",
        header: "Status",
        size: 75,
        minSize: 75,
        accessorKey: "status",
        cell: ({ row }) => {
          const status = _.get(row, "original.status");

          let color;
          if (status == "completed") {
            color = colors.green[500];
          } else if (status == "failed") {
            color = colors.red[500];
          } else {
            color = colors.yellow[500];
          }

          return (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Circle style={{ color, fontSize: "16px" }} />
            </div>
          );
        },
        enableColumnFilter: false,
        enableHiding: false,
        sortDescFirst: false,
      },
      {
        id: "_id",
        header: "Event ID",
        size: 175,
        minSize: 150,
        accessorKey: "_id",
        enableHiding: false,
        filterFn: "includesString",
        sortDescFirst: false,
      },
      {
        id: "lastAttempt",
        header: "Last Sendout",
        size: 175,
        minSize: 160,
        accessorFn: (row) =>
          moment(_.get(row, "lastAttempt")).format("YYYY-MM-DD HH:mm:ss"),
        sortingFn: (rowA, rowB, colId) => {
          const dateA = new Date(_.get(rowA, "original.lastAttempt"));
          const dateB = new Date(_.get(rowB, "original.lastAttempt"));

          return getSortVal(dateA, dateB);
        },
        filterFn: "includesString",
        enableHiding: false,
        sortDescFirst: false,
      },
      {
        header: "Type",
        size: 150,
        minSize: 125,
        accessorKey: "type",
        filterFn: "includesString",
      },
      {
        id: "url",
        header: "URL",
        size: 175,
        minSize: 150,
        accessorKey: "url",
        filterFn: "includesString",
        sortDescFirst: false,
      },
      {
        id: "retryCount",
        header: "Retry Count",
        size: 175,
        minSize: 150,
        accessorKey: "retryCount",
        filterFn: "includesString",
        sortDescFirst: false,
        isNum: true,
      },
    ];
  }, []);

  return (
    <>
      <Grid
        className="w-full overflow-hidden rounded-lg"
        style={{
          margin: theme.spacing(2),
          padding: theme.spacing(3),
          backgroundColor: colors.grey[100],
        }}
      >
        <Typography variant="h4">Webhook Events</Typography>
        <Divider
          style={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
          }}
        />
        <LocalTable
          name="webhook-events-table"
          data={_.get(data, "webhookEvents", [])}
          columns={columns}
          manualPagination={_.omit(data, ["webhookEvents"])}
        />
      </Grid>
      <JSONDialog
        open={showJSONDialog}
        loading={jsonLoading}
        json={json}
        onClose={() => {
          setShowJSONDialog(false);
          setJSON({});
        }}
      />
    </>
  );
};

export default WebhookEvents;
