import { useEffect, useContext } from "react";
import MobileContext from "../contexts/Mobile/MobileContext";

const useMobile = () => {
  const mobileCtx = useContext(MobileContext);
  const { setIsMobile } = mobileCtx;

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkIsMobile();

    window.addEventListener("resize", checkIsMobile);
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  return mobileCtx;
};

export default useMobile;
