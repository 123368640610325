import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useFormik } from "formik";

import useHttp from "../../hooks/useHttp";
import useAuth from "../../hooks/useAuth";

import { useTheme } from "@mui/material/styles";

import {
  Button,
  Grid,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  OutlinedInput,
  Container,
  Card,
  CardContent,
  Stack,
  Avatar,
} from "@mui/material";

import { Login as LoginIcon } from "@mui/icons-material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const ERRORS = {
  400: "An error occurred while processing your login",
  401: "Invalid credentials provided",
  404: "Invalid credentials provided",
};

function Login({ authenticate }) {
  const theme = useTheme();
  const { post } = useHttp();

  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [loginError, setLoginError] = useState("");

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      setShowPassword(false);

      const res = await post("/login", values);
      if (res.status >= 400) {
        setLoginError(ERRORS[res.status]);
      } else {
        authenticate(res);
        setLoginError("");

        const from = location.state?.from?.pathname || "/dashboard";
        navigate(from, { replace: true });
      }

      setLoading(false);
    },
  });

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      formik.handleSubmit();
    }
  };

  return (
    <Container
      maxWidth={false}
      sx={{ backgroundColor: theme.palette.grey[100] }}
      className="flex items-center justify-center h-screen"
    >
      <Card raised className="max-w-xl">
        <CardContent sx={{ my: 6, mx: { xs: 2, sm: 6 } }}>
          <Stack spacing={2} className="text-center items-center">
            <Avatar
              className="h-fit w-fit"
              sx={{ bgcolor: "primary.main", p: 1.5 }}
            >
              <LoginIcon sx={{ fontSize: 30 }} />
            </Avatar>
            <Typography variant="h4">MDDS</Typography>
            <Typography>
              Login to your account or message a system administrator to create
              an account for you.
            </Typography>
          </Stack>
          <Stack spacing={1} sx={{ mt: 4 }} className="items-center">
            <TextField
              fullWidth
              variant="outlined"
              id="email"
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              disabled={loading}
              onKeyDown={handleKeyDown}
            />
            <FormControl fullWidth variant="outlined" disabled={loading}>
              <InputLabel htmlFor="password">Password</InputLabel>
              <OutlinedInput
                id="password"
                name="password"
                label="Password"
                value={formik.values.password}
                type={showPassword ? "text" : "password"}
                onChange={formik.handleChange}
                onKeyDown={handleKeyDown}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      disabled={loading}
                      onClick={(e) => {
                        e.preventDefault();
                        setShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Typography
              variant="body2"
              sx={{ color: theme.palette.error.light }}
            >
              {loginError}
            </Typography>
          </Stack>
          <Button
            fullWidth
            variant="contained"
            disabled={loading}
            onClick={formik.handleSubmit}
            sx={{ mt: 2 }}
          >
            Login
          </Button>
        </CardContent>
      </Card>
    </Container>
  );
}

export default Login;
