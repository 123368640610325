import React from "react";
import _ from "lodash";

import { useTheme } from "@mui/material/styles";
import {
  ListItemIcon,
  ListItemText,
  MenuList,
  MenuItem,
  Typography,
} from "@mui/material";

import VpnKey from "@mui/icons-material/VpnKey";
import Home from "@mui/icons-material/Home";
import ImageIcon from "@mui/icons-material/Image";
import PhotoSizeSelectLargeIcon from "@mui/icons-material/PhotoSizeSelectLarge";
import {
  Build,
  Map,
  Person,
  Settings,
  Storefront,
  TableView,
  Receipt,
  Recycling,
  Webhook,
  History,
  QrCode,
  Article,
  PunchClock,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import useMobile from "../../hooks/useMobile";
import useHttp from "../../hooks/useHttp";
import { hasRoleAccess } from "../../utils/misc";

function Navigation(props) {
  const theme = useTheme();
  const { get, post } = useHttp();
  const { authenticated } = useAuth();
  const { isMobile } = useMobile();

  const { visible, handleClose } = props;

  const handleLinkClick = () => {
    if (isMobile) {
      handleClose();
    }
  };

  const historyLinks = [
    {
      label: "Containers",
      icon: <Recycling />,
      path: "/dashboard/containers",
      permissionLevel: "System Administrator",
    },
    {
      label: "Transactions",
      icon: <Receipt />,
      path: "/dashboard/transactions",
      permissionLevel: "System Administrator",
    },
    {
      label: "Webhook Events",
      icon: <History />,
      path: "/dashboard/webhookEvents",
      permissionLevel: "System Administrator",
    },
  ];

  const configurationLinks = [
    {
      label: "API Keys",
      icon: <VpnKey />,
      path: "/dashboard/api",
      permissionLevel: "Vendor Employee",
    },
    {
      label: "Devices",
      icon: <Build />,
      path: "/dashboard/devices",
      permissionLevel: "Vendor Employee",
    },
    {
      label: "Deposit Groups",
      icon: <TableView />,
      path: "/dashboard/depositGroups",
      permissionLevel: "System Administrator",
    },
    {
      label: "Locations",
      icon: <Map />,
      path: "/dashboard/locations",
      permissionLevel: "System Administrator",
    },
    {
      label: "Users",
      icon: <Person />,
      path: "/dashboard/users",
      permissionLevel: "Vendor Administrator",
    },
    {
      label: "Vendors",
      icon: <Storefront />,
      path: "/dashboard/vendors",
      permissionLevel: "System Administrator",
    },
    {
      label: "Webhooks",
      icon: <Webhook />,
      path: "/dashboard/webhooks",
      permissionLevel: "System Administrator",
    },
    {
      label: "QR Lookup Table",
      icon: <QrCode />,
      path: "/dashboard/qrcodes",
      permissionLevel: "System Administrator",
    },
    {
      label: "Operators",
      icon: <PunchClock />,
      path: "/dashboard/operators",
      permissionLevel: "System Administrator",
    },
  ];

  const handleDownload = async () => {
    try {
      const response = await get("/doc", {}, { responseType: "blob" });
      if (response.status >= 300) {
        throw new Error("Request for download failed");
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "MDDS_API_SPEC.odt");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      // console.error(err);
    }
  };

  return (
    <div
      style={{
        width: visible ? "300px" : 0,
        minWidth: visible ? "300px" : 0,
        maxWidth: visible ? "300px" : 0,
        display: "flex",
        flexDirection: "column",
        transition: "0.3s",
        overflow: "hidden",
      }}
    >
      {hasRoleAccess(authenticated.roles, "System Administrator") && (
        <Typography
          style={{
            fontWeight: 500,
            margin: theme.spacing(2),
            marginBottom: 0,
          }}
        >
          History
        </Typography>
      )}
      <MenuList style={{ marginLeft: theme.spacing(2) }}>
        {_.sortBy(historyLinks, ["label"]).map((linkObj) => {
          const { label, icon, path, permissionLevel } = linkObj || {};

          if (hasRoleAccess(authenticated.roles, permissionLevel))
            return (
              <Link
                to={path}
                style={{ textDecoration: "none", color: "inherit" }}
                onClick={handleLinkClick}
                key={_.camelCase(label)}
              >
                <MenuItem>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{label}</ListItemText>
                </MenuItem>
              </Link>
            );
        })}
      </MenuList>

      <Typography
        style={{ fontWeight: 500, margin: theme.spacing(2), marginBottom: 0 }}
      >
        Configuration
      </Typography>
      <MenuList style={{ marginLeft: theme.spacing(2) }}>
        {_.sortBy(configurationLinks, ["label"]).map((linkObj) => {
          const { label, icon, path, permissionLevel } = linkObj || {};

          if (hasRoleAccess(authenticated.roles, permissionLevel))
            return (
              <Link
                to={path}
                style={{ textDecoration: "none", color: "inherit" }}
                onClick={handleLinkClick}
                key={_.camelCase(label)}
              >
                <MenuItem>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{label}</ListItemText>
                </MenuItem>
              </Link>
            );
        })}
      </MenuList>
      {hasRoleAccess(authenticated.roles, "System Administrator") && (
        <>
          <Typography
            style={{
              fontWeight: 500,
              margin: theme.spacing(2),
              marginBottom: 0,
            }}
          >
            Downloads
          </Typography>
          <MenuList style={{ marginLeft: theme.spacing(2) }}>
            <Link
              // to="/dashboard/transactions"
              style={{ textDecoration: "none", color: "inherit" }}
              onClick={handleDownload}
              key="api-doc"
            >
              <MenuItem>
                <ListItemIcon>
                  <Article />
                </ListItemIcon>
                <ListItemText>API Doc</ListItemText>
              </MenuItem>
            </Link>
          </MenuList>
        </>
      )}
    </div>
  );
}

export default Navigation;
