import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";

import { TextField, Grid } from "@mui/material";

import DefaultDialog from "../Dialogs/DefaultDialog";
import { TagInput } from "../Dialogs/controls";

const CreateEditQRCodeDialog = ({ open, setOpen, qrCode, handleSubmit }) => {
  const isCreate = _.isNil(qrCode);

  const schema = Yup.object().shape({
    prefix: Yup.string().required("Prefix is required"),
    url: Yup.string().url().required("URL is required"),
  });

  const getInitialValues = (qrCode) => {
    let initVals = {
      prefix: "",
      url: "",
    };

    if (!_.isNil(qrCode)) {
      initVals = _.mergeWith({}, initVals, qrCode, (objVal, srcVal) => {
        if (_.isArray(srcVal)) {
          return srcVal; // needed for allowedDevices
        } else if (_.isObject(srcVal)) {
          return _.get(srcVal, "_id");
        }
      });
    }

    return initVals;
  };

  const formik = useFormik({
    initialValues: getInitialValues(qrCode),
    validationSchema: schema,
    onSubmit: (values, { setSubmitting }) => {
      try {
        const qrCodeToSubmit = _.cloneDeep(values);

        handleSubmit(qrCodeToSubmit);
        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    },
    enableReinitialize: true,
  });

  const {
    values,
    errors,
    touched,
    handleSubmit: handleSubmitFormik,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  return (
    <DefaultDialog
      open={open}
      title={`${isCreate ? "Create" : "Edit"} Lookup`}
      iconType={isCreate ? "add" : "edit"}
      handleClose={() => setOpen(false)}
      handleConfirm={handleSubmitFormik}
      confirmButtonName={isCreate ? "Create" : "Update"}
      closeButtonName="Cancel"
      disableConfirm={isSubmitting}
    >
      <FormikProvider value={formik}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              disabled={!isCreate}
              {...getFieldProps("prefix")}
              error={Boolean(touched.prefix && errors.prefix)}
              helperText={touched.prefix && errors.prefix}
              label="Prefix"
              InputLabelProps={{
                style: {
                  height: "1.2em",
                },
                shrink: !_.isEmpty(values.prefix),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              {...getFieldProps("url")}
              error={Boolean(touched.url && errors.url)}
              helperText={touched.url && errors.url}
              label="URL"
              InputLabelProps={{
                style: {
                  height: "1.2em",
                },
                shrink: !_.isEmpty(values.url),
              }}
            />
          </Grid>
        </Grid>
      </FormikProvider>
    </DefaultDialog>
  );
};

export default CreateEditQRCodeDialog;
