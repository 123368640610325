import { useState, forwardRef, useEffect } from "react";
import {
  Fade,
  Slide,
  Box,
  Autocomplete,
  TextField,
  Chip,
  Tooltip,
} from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

export const FadeTransition = forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

export const SlideUpTransition = forwardRef(function Transition(props, ref) {
  return <Slide ref={ref} direction="up" {...props} />;
});

export const Section = ({ title, children, theme }) => (
  <Box
    component="fieldset"
    sx={{
      borderColor: theme.palette.grey[50],
      padding: 3,
      paddingTop: 2.5,
      borderRadius: "0.25rem",
    }}
  >
    {title && (
      <legend
        style={{
          fontSize: theme.typography.body1.fontSize,
          fontFamily: theme.typography.body1.fontFamily,
        }}
      >
        {title}
      </legend>
    )}
    {children}
  </Box>
);

export const TagInput = ({
  value,
  setValue,
  label,
  helperText,
  disableClearable = false,
  regex,
  regexErrorText,
  reset,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [hasRegexError, setHasRegexError] = useState(false);

  const handleChange = (val, reason) => {
    if (reason === "createOption") {
      const valToAdd = val[val.length - 1];
      if (!regex || regex.test(valToAdd)) {
        setValue(val);
        setInputValue("");
        setHasRegexError(false);
      } else {
        setHasRegexError(true);
        setInputValue(valToAdd);
      }
    } else {
      setValue(val);
      setHasRegexError(false);
    }
  };

  useEffect(() => {
    setInputValue("");
    setHasRegexError(false);
  }, [reset]);

  return (
    <Autocomplete
      multiple
      freeSolo
      disableClearable={disableClearable}
      options={[]}
      inputValue={inputValue}
      onInputChange={(event, val) => setInputValue(val)}
      value={value}
      onChange={(event, val, reason) => {
        if (["createOption", "removeOption", "clear"].includes(reason)) {
          handleChange(val, reason);
        }
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index });
          return <Chip label={option} key={key} {...tagProps} />;
        })
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || "Tags"}
          placeholder="Type and press enter to add"
          helperText={
            hasRegexError
              ? regexErrorText || "Typed value invalid format"
              : helperText
          }
          error={hasRegexError}
        />
      )}
    />
  );
};

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
