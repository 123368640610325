import React, { useEffect, useState } from "react";
import _ from "lodash";
import { jwtDecode } from "jwt-decode";
import { Route, Routes, Navigate, Outlet } from "react-router-dom";

import Login from "./Login/Login";
import Register from "./Register/Register";

import { CircularProgress, Grid } from "@mui/material";

import useAuth from "../hooks/useAuth";
import useHttp from "../hooks/useHttp";
import useMobile from "../hooks/useMobile";
import RequireAuth from "../components/RequireAuth";

import APIKeysNew from "../components/APIKeys/APIKeysNew";
import Vendors from "../components/Vendors/Vendors";
import Users from "../components/Users/Users";
import Locations from "../components/Locations/Locations";
import Devices from "../components/Devices/Devices";
import ForgotPassword from "./Password/ForgotPassword";
import ResetPassword from "./Password/ResetPassword";
import Containers from "../components/Containers/Containers";
import DepositGroups from "../components/DepositGroups/DepositGroups";
import Transactions from "../components/Transactions/Transactions";
import WebhookEvents from "../components/WebhookEvents/WebhookEvents";
import Webhooks from "../components/Webhooks/Webhooks";
import QRCodes from "../components/QRCodes/QRCodes";
import Operators from "../components/Operators/Operators";

function Pages() {
  const { get } = useHttp();
  const { authenticated, setAuthenticated } = useAuth();
  const {} = useMobile();

  const [loading, setLoading] = useState(true);

  const handleAuthentication = (res) => {
    // Handles authentication responses
    const accessToken = _.get(res, "data.accessToken");
    if (!accessToken) return;

    localStorage.setItem("accessToken", accessToken);
    setAuthenticated(generateAuthenticatedObjFromToken(accessToken));
  };

  useEffect(() => {
    // Hook to handle persistent logins
    if (authenticated) return;

    setLoading(true);

    (async () => {
      const res = await get("/checkStatus");
      if (res.status === 200) {
        const accessToken = localStorage.getItem("accessToken");
        setAuthenticated(generateAuthenticatedObjFromToken(accessToken));
      }

      setLoading(false);
    })();
  }, [authenticated]);

  if (loading) {
    return (
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Routes>
      <Route
        path="/login"
        element={
          authenticated ? (
            <Navigate to="/dashboard" />
          ) : (
            <Login authenticate={handleAuthentication} />
          )
        }
      />
      {/* <Route
        path="/register"
        element={<Register authenticate={handleAuthentication} />}
      /> */}
      <Route
        path="/"
        element={
          authenticated ? (
            <Navigate to="/dashboard" />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      {/* <Route path="forgotPassword" element={<ForgotPassword />} /> */}
      <Route path="resetPassword" element={<ResetPassword />} />
      {/* Protected routes */}
      <Route path="/dashboard/*" element={<Outlet />}>
        <Route element={<RequireAuth roleAccess="System Administrator" />}>
          <Route path="vendors" element={<Vendors />} />
          <Route path="locations" element={<Locations />} />
          <Route path="depositGroups" element={<DepositGroups />} />
          <Route path="containers" element={<Containers />} />
          <Route path="transactions" element={<Transactions />} />
          <Route path="webhookEvents" element={<WebhookEvents />} />
          <Route path="webhooks" element={<Webhooks />} />
          <Route path="qrcodes" element={<QRCodes />} />
          <Route path="operators" element={<Operators />} />
        </Route>
        <Route element={<RequireAuth roleAccess="Vendor Administrator" />}>
          <Route path="users" element={<Users />} />
        </Route>
        <Route element={<RequireAuth roleAccess="Vendor Employee" />}>
          <Route path="api" element={<APIKeysNew />} />
          <Route path="devices" element={<Devices />} />
        </Route>

        {/* /dashboard fallback */}
        <Route path="*" element={<Navigate to="devices" />} />
      </Route>

      {/* Root fallback */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default Pages;

function generateAuthenticatedObjFromToken(token) {
  const decodedToken = jwtDecode(token);

  return { roles: decodedToken.roles };
}
