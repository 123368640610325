export const NAME_REGEX = /^[A-Za-z]{2,40}$/;
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
export const KEY_NAME_REGEX = /^[a-zA-Z0-9\s-]*$/;
export const IPV4_REGEX =
  /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])$/;

export const ROLES_ARR = [
  "Vendor Employee",
  "Vendor Administrator",
  "System Administrator",
];

export const DEVICE_TYPES = ["aa", "ai", "por", "rvm", "atm"];

export const OPERATOR_ROLES = ["Clerk", "Manager"];

export const API_FUNCTIONALITIES = [
  "Container Submissions",
  "ATM Transactions",
  "QR Code Submissions",
];

export const EPAYMENT_WEBHOOK_TYPE = "E-Payment Sendouts";
export const QR_WEBHOOK_TYPE = "QR Code Sendouts";
export const WEBHOOK_FUNCTIONALITIES = [EPAYMENT_WEBHOOK_TYPE, QR_WEBHOOK_TYPE];
