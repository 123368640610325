import React, { useState } from "react";
import _ from "lodash";
import * as Yup from "yup";

import { useSearchParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import useHttp from "../../hooks/useHttp";
import useSnackbar from "../../hooks/useSnackbar";
import useLoading from "../../hooks/useLoading";

import {
  Card,
  CardContent,
  Avatar,
  Button,
  TextField,
  Box,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  Stack,
} from "@mui/material";

import { LockReset, Visibility, VisibilityOff } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

const ResetPassword = () => {
  const theme = useTheme();
  const { openSnackbar } = useSnackbar();
  const { post } = useHttp();
  const { setLoading } = useLoading();

  const [searchParams] = useSearchParams();
  let navigate = useNavigate();

  const token = searchParams.get("token");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSubmit = async (password) => {
    setLoading(true);
    const res = await post("/resetPassword", {
      password,
      token: token,
    });
    const isSuccessful = res.status < 400;

    if (isSuccessful) navigate("/login", { replace: true });

    openSnackbar({
      open: true,
      message: isSuccessful
        ? "Password reset successful"
        : _.get(res, "data.error", "Failed to reset password"),
      variant: "alert",
      alert: {
        color: isSuccessful ? "success" : "error",
      },
      close: false,
    });
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Password confirmation is required"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      try {
        handleSubmit(values.password);
        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    },
    enableReinitialize: true,
  });

  const {
    handleSubmit: handleSubmitFormik,
    getFieldProps,
    values,
    errors,
    touched,
  } = formik;

  return (
    <Container
      maxWidth={false}
      sx={{ backgroundColor: theme.palette.grey[100] }}
      className="flex items-center justify-center h-screen"
    >
      <Card raised className="w-[576px]">
        <CardContent sx={{ my: 6, mx: { xs: 2, sm: 6 } }}>
          <Stack spacing={2} className="text-center items-center">
            <Avatar
              className="h-fit w-fit"
              sx={{ bgcolor: "primary.main", p: 1.5 }}
            >
              <LockReset sx={{ fontSize: 30 }} />
            </Avatar>
            <Typography variant="h4">MDDS</Typography>
            <Typography>Enter a new password for your account</Typography>
          </Stack>
          <Stack spacing={1} sx={{ mt: 4 }} className="items-center">
            <TextField
              required
              fullWidth
              type={showPassword ? "text" : "password"}
              {...getFieldProps("password")}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              label="Password"
              InputLabelProps={{
                style: {
                  height: "1.2em",
                },
                shrink: !_.isEmpty(values.password),
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={(e) => {
                        e.preventDefault();
                        setShowPassword((prev) => !prev);
                      }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              required
              fullWidth
              type={showConfirmPassword ? "text" : "password"}
              {...getFieldProps("confirmPassword")}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              helperText={touched.confirmPassword && errors.confirmPassword}
              label="Confirm Password"
              InputLabelProps={{
                style: {
                  height: "1.2em",
                },
                shrink: !_.isEmpty(values.confirmPassword),
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={(e) => {
                        e.preventDefault();
                        setShowConfirmPassword((prev) => !prev);
                      }}
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            onClick={handleSubmitFormik}
            sx={{ mt: 2 }}
          >
            Submit
          </Button>
        </CardContent>
      </Card>
    </Container>
  );
};

export default ResetPassword;
