import _ from "lodash";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const get = async (url, params, axiosConfig = {}) => {
  try {
    let accessToken = localStorage.getItem("accessToken");
    if (!isAuthEndpoint(url) && (!accessToken || isTokenExpired(accessToken))) {
      accessToken = await getNewToken();
    }

    const res = await axios({
      method: "GET",
      url: API_ENDPOINT + url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
      ...axiosConfig,
      withCredentials: isLogoutEndpoint(url), // Need to pass cookie back for logging out
    });

    // console.log({ res });

    return res;
  } catch (err) {
    console.error(err);

    const response = _.get(err, "response");
    if (response) {
      return response;
    }

    return { status: 400 };
  }
};

export const post = async (url, data, type = "application/json") => {
  try {
    let accessToken = localStorage.getItem("accessToken");

    if (!isAuthEndpoint(url) && (!accessToken || isTokenExpired(accessToken))) {
      accessToken = await getNewToken();
    }

    const res = await axios({
      method: "POST",
      url: API_ENDPOINT + url,
      data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": type,
      },
      withCredentials: isAuthEndpoint(url),
    });

    // console.log(res);

    return res;
  } catch (err) {
    console.error(err);

    const response = _.get(err, "response");
    if (response) {
      return response;
    }

    return { status: 400 };
  }
};

function isAuthEndpoint(url) {
  return ["/login", "/register", "/resetPassword"].includes(url);
}

function isLogoutEndpoint(url) {
  return url === "/logout";
}

function isTokenExpired(token) {
  const decodedToken = jwtDecode(token);
  const expirationDate = new Date(decodedToken.exp * 1000);

  return new Date() > expirationDate;
}

async function getNewToken() {
  const authenticationRes = await axios({
    method: "get",
    url: API_ENDPOINT + "/refreshAuth",
    withCredentials: true,
  });

  if (authenticationRes.status !== 200) {
    throw new Error("An authentication error occurred");
  }

  const accessToken = _.get(authenticationRes, "data.accessToken");
  if (!accessToken) {
    throw new Error("An authentication error occurred");
  }

  localStorage.setItem("accessToken", accessToken);
  return localStorage.getItem("accessToken");
}
