import React, { useEffect, useMemo } from "react";
import _ from "lodash";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";
import { DEVICE_TYPES } from "../../constants";
import useAuth from "../../hooks/useAuth";

import {
  TextField,
  Grid,
  MenuItem,
  Switch,
  Typography,
  Stack,
  Collapse,
} from "@mui/material";

import DefaultDialog from "../Dialogs/DefaultDialog";
import { reverseArrDict } from "../../utils/misc";

const CreateEditDeviceDialog = ({
  open,
  setOpen,
  device,
  handleSubmit,
  vendorOptions = [],
  locationOptions = [],
}) => {
  const isCreate = _.isNil(device);

  const { authenticated } = useAuth();

  const schema = Yup.object().shape({
    serialNumber: Yup.string().required("Serial number is required"),
    type: Yup.string().required("Type is required"),
    location: Yup.string().required("Location is required"),
    vendor: Yup.string().required("Vendor is required"),
    active: Yup.boolean(),
  });

  const getInitialValues = (device) => {
    let initVals = {
      serialNumber: "",
      type: "",
      location: "",
      vendor: "",
      active: true,
    };

    if (!_.isNil(device)) {
      initVals = _.mergeWith({}, initVals, device, (objVal, srcVal) => {
        if (_.isObject(srcVal)) {
          return _.get(srcVal, "_id");
        }
      });
    }

    if (vendorOptions.length === 1)
      initVals.vendor = _.get(vendorOptions, ["0", "_id"]);

    return initVals;
  };

  const formik = useFormik({
    initialValues: getInitialValues(device),
    validationSchema: schema,
    onSubmit: (values, { setSubmitting }) => {
      try {
        handleSubmit(values);
        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    },
    enableReinitialize: true,
  });

  const {
    values,
    errors,
    touched,
    handleSubmit: handleSubmitFormik,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;

  const vendorDict = useMemo(() => {
    return vendorOptions.reduce((dict, vendor) => {
      const { _id } = vendor;
      if (!_.has(dict, _id)) {
        dict[_id] = {
          locations: _.get(vendor, "allowedLocations", []).map((loc) =>
            _.get(loc, "_id", loc)
          ),
          deviceTypes: _.get(vendor, "deviceTypes", []),
        };
      }
      return dict;
    }, {});
  }, [vendorOptions]);

  // filter device types by selected vendor
  const filteredDeviceTypes = useMemo(() => {
    const selectedVendor = values.vendor;
    if (_.isEmpty(selectedVendor)) {
      return DEVICE_TYPES;
    }

    const vendorDeviceTypes = _.get(
      vendorDict,
      [selectedVendor, "deviceTypes"],
      []
    );

    return DEVICE_TYPES.filter((type) => vendorDeviceTypes.includes(type));
  }, [values.vendor, vendorDict]);

  // filter locations by selected vendor
  const filteredLocations = useMemo(() => {
    const selectedVendor = values.vendor;
    if (_.isEmpty(selectedVendor)) {
      return locationOptions;
    }

    const vendorLocations = _.get(
      vendorDict,
      [selectedVendor, "locations"],
      []
    );

    return locationOptions.filter(({ _id }) => vendorLocations.includes(_id));
  }, [values.vendor, locationOptions, vendorDict]);

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  /* Clears the type field when it finds that the selected type is not one of the allowed device types
  for the selected vendor */
  useEffect(() => {
    const selectedType = values.type;
    if (
      !_.isEmpty(selectedType) &&
      !_.includes(filteredDeviceTypes, selectedType)
    ) {
      setFieldTouched("type");
      setFieldValue("type", "");
    }
  }, [filteredDeviceTypes, values.type]);

  /* Clears the location field when it finds that the selected location is not one of the allowed locations
  for the selected vendor */
  useEffect(() => {
    const selectedLocation = values.location;
    if (
      !_.isEmpty(selectedLocation) &&
      !_.some(filteredLocations, ["_id", selectedLocation])
    ) {
      setFieldTouched("location");
      setFieldValue("location", "");
    }
  }, [filteredLocations, values.location]);

  return (
    <DefaultDialog
      open={open}
      title={`${isCreate ? "Create" : "Edit"} Device`}
      iconType={isCreate ? "add" : "edit"}
      handleClose={() => setOpen(false)}
      handleConfirm={handleSubmitFormik}
      confirmButtonName={isCreate ? "Create" : "Update"}
      closeButtonName="Cancel"
      disableConfirm={isSubmitting}
    >
      <FormikProvider value={formik}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              {...getFieldProps("serialNumber")}
              error={Boolean(touched.serialNumber && errors.serialNumber)}
              helperText={touched.serialNumber && errors.serialNumber}
              label="Serial Number"
              InputLabelProps={{
                style: {
                  height: "1.2em",
                },
                shrink: !_.isEmpty(values.serialNumber),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              select
              fullWidth
              disabled={!isCreate}
              {...getFieldProps("vendor")}
              error={Boolean(touched.vendor && errors.vendor)}
              helperText={touched.vendor && errors.vendor}
              label="Vendor"
              InputLabelProps={{
                style: {
                  height: "1.2em",
                },
                shrink: !_.isEmpty(values.vendor),
              }}
              onChange={(e) => {
                const vendor = e.target.value;
                setFieldValue("vendor", vendor);
                setFieldValue("location", "");
                setFieldValue("type", "");
              }}
            >
              {_.isEmpty(vendorOptions) ? (
                <MenuItem disabled={true}>No vendors</MenuItem>
              ) : (
                vendorOptions.map((vendor) => (
                  <MenuItem value={vendor._id} key={vendor._id}>
                    {_.get(vendor, "name", "Unknown")}
                  </MenuItem>
                ))
              )}
            </TextField>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              padding: _.isEmpty(values.vendor) ? "0 !important" : "inherit",
            }}
          >
            <Collapse in={!_.isEmpty(values.vendor)}>
              <Stack spacing={2}>
                <TextField
                  required
                  select
                  fullWidth
                  {...getFieldProps("type")}
                  error={Boolean(touched.type && errors.type)}
                  helperText={touched.type && errors.type}
                  label="Type"
                  InputLabelProps={{
                    style: {
                      height: "1.2em",
                    },
                    shrink: !_.isEmpty(values.type),
                  }}
                >
                  {filteredDeviceTypes.map((t) => (
                    <MenuItem value={t} key={t}>
                      {_.upperCase(t)}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  required
                  select
                  fullWidth
                  {...getFieldProps("location")}
                  error={Boolean(touched.location && errors.location)}
                  helperText={touched.location && errors.location}
                  label="Location"
                  InputLabelProps={{
                    style: {
                      height: "1.2em",
                    },
                    shrink: !_.isEmpty(values.location),
                  }}
                >
                  {_.isEmpty(filteredLocations) ? (
                    <MenuItem disabled={true}>No locations</MenuItem>
                  ) : (
                    filteredLocations.map((location) => (
                      <MenuItem value={location._id} key={location._id}>
                        {`${location.name} (${location.collectorCode})`}
                      </MenuItem>
                    ))
                  )}
                </TextField>
              </Stack>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Switch
                checked={values.active}
                onChange={(event) =>
                  setFieldValue("active", event.target.checked)
                }
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography>Active</Typography>
            </Stack>
          </Grid>
        </Grid>
      </FormikProvider>
    </DefaultDialog>
  );
};

export default CreateEditDeviceDialog;
