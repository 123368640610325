import React, { useEffect, useState } from "react";
import _ from "lodash";
import useHttp from "../../hooks/useHttp";

import VirtualizedAutocompleteLazy from "./VirtualizedAutocompleteLazy";

const VAutocompleteLazyWrapper = ({
  id,
  endpoint,
  resultsKey,
  endpointQuery = {},
  rowLimit = 10,
  ...props
}) => {
  const { get } = useHttp();

  const [hasMoreData, setHasMoreData] = useState(true);
  const [isMoreDataLoading, setIsMoreDataLoading] = useState(false);

  const [data, setData] = useState([]);
  const [skip, setSkip] = useState(0);
  const [search, setSearch] = useState("");

  const initData = async () => {
    setIsMoreDataLoading(true);

    const res = await get(endpoint, {
      skip: 0,
      limit: rowLimit,
      search,
      ...endpointQuery,
    });

    if (res.status < 400) {
      setData(_.get(res, ["data", resultsKey], []));
      setSkip(rowLimit);
      setHasMoreData(_.get(res, "data.hasMore", false));
    } else {
      console.log(`Error fetching ${resultsKey}`);
    }

    setIsMoreDataLoading(false);
  };

  const loadMoreData = async (...args) => {
    setIsMoreDataLoading(true);

    const res = await get(endpoint, {
      skip,
      limit: rowLimit,
      search,
      ...endpointQuery,
    });

    if (res.status < 400) {
      setData((prevData) => {
        const newData = _.get(res, ["data", resultsKey], []);
        return [...prevData, ...newData];
      });

      setSkip((prevSkip) => prevSkip + rowLimit);
      setHasMoreData(_.get(res, "data.hasMore", false));
    } else {
      console.log(`Error fetching ${resultsKey}`);
    }

    setIsMoreDataLoading(false);
  };

  // **Debounced
  useEffect(() => {
    const timeout = setTimeout(() => {
      initData();
    }, 500);

    return () => clearTimeout(timeout);
  }, [search, JSON.stringify(endpointQuery)]);

  // **Non-debounced
  // useEffect(() => {
  //   initData();
  // }, [search, JSON.stringify(endpointQuery)]);

  return (
    <VirtualizedAutocompleteLazy
      {...props}
      id={id}
      options={data}
      rowLimit={rowLimit}
      inputValue={search}
      handleSearch={setSearch}
      loadMoreData={loadMoreData}
      hasMoreData={hasMoreData}
      isMoreDataLoading={isMoreDataLoading}
    />
  );
};

export default VAutocompleteLazyWrapper;
