import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import moment from "moment-timezone";

import useHttp from "../../hooks/useHttp";
import useLoading from "../../hooks/useLoading";
import useSnackbar from "../../hooks/useSnackbar";
import useAuth from "../../hooks/useAuth";

import { makeData } from "../Table/dataTest";

import {
  Button,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography,
  colors,
  Popover,
} from "@mui/material";
import { Add, LockReset } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

import { fuzzyFilter, arraySort, getSortVal, enumFilter } from "../Table/misc";
import { ActionCell } from "../Table/controls";

import LocalTable from "../Table/LocalTable";
import DeleteDialog from "../Dialogs/DeleteDialog";
import CreateEditUserDialog from "./CreateEditUserDialog";

const Users = () => {
  const theme = useTheme();
  const { get, post } = useHttp();
  const { authenticated } = useAuth();
  const { loading, setLoading } = useLoading();
  const { openSnackbar } = useSnackbar();

  const [editingUser, setEditingUser] = useState();
  const [showEditUserDialog, setShowEditUserDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState();
  const [showDeleteUserDialog, setShowDeleteUserDialog] = useState(false);

  const [allVendors, setAllVendors] = useState([]);
  // const [data, setData] = useState(() => makeData(50, "user"));
  const [data, setData] = useState([]);

  const [refetch, setRefetch] = useState(false);

  const isSystemAdmin = authenticated.roles.includes("System Administrator");

  const getVendors = async () => {
    setLoading(true);
    const res = await get("/getVendors");
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setAllVendors(res.data.vendors);
    }
    setLoading(false);
  };

  const getUsers = async () => {
    setLoading(true);
    const res = await get("/getUsers");
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setData(res.data.users);
    }
    setLoading(false);
  };

  const handleCreateUser = async (newUser) => {
    setLoading(true);
    const res = await post("/createUser", { newUser });
    const isSuccessful = res.status < 400;

    let showLink = false,
      resetPwLink = "";
    if (isSuccessful) {
      setShowEditUserDialog(false);
      setRefetch((prev) => !prev);

      resetPwLink = res.data.resetPasswordLink;

      // Copy the string to the clipboard
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(resetPwLink);
        console.log("Text copied to clipboard");
      } else {
        showLink = true;
        console.warn("Clipboard API not supported");
      }
    }

    openSnackbar({
      open: true,
      message: isSuccessful
        ? showLink
          ? `Please send the password reset link to new user:\n${resetPwLink}`
          : "Reset password link for new user copied to clipboard"
        : "Failed to create new user",
      variant: "alert",
      alert: {
        color: isSuccessful ? "success" : "error",
      },
      close: false,
    });
    setLoading(false);
  };

  const handleEditUser = async (newUser) => {
    setLoading(true);
    const res = await post("/updateUser", {
      newUser,
      _id: editingUser._id,
    });
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setShowEditUserDialog(false);
      setRefetch((prev) => !prev);
    }

    openSnackbar({
      open: true,
      message: isSuccessful
        ? "Successfully updated user details"
        : "Failed to update user details",
      variant: "alert",
      alert: {
        color: isSuccessful ? "success" : "error",
      },
      close: false,
    });
    setLoading(false);
  };

  const handleDeleteUser = async () => {
    setLoading(true);
    const res = await post("/deleteUser", {
      _id: userToDelete._id,
    });
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setShowDeleteUserDialog(false);
      setRefetch((prev) => !prev);
    }

    openSnackbar({
      open: true,
      message: isSuccessful
        ? "Successfully deleted user"
        : "Failed to delete user",
      variant: "alert",
      alert: {
        color: isSuccessful ? "success" : "error",
      },
      close: false,
    });
    setLoading(false);
  };

  const handleResetPassword = async (user) => {
    const res = await post("/forgotPassword", {
      userId: user._id,
    });
    const isSuccessful = res.status < 400;

    let showLink = false,
      resetPwLink = "";
    if (isSuccessful) {
      resetPwLink = res.data.resetPasswordLink;

      // Copy the string to the clipboard
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(resetPwLink);
        console.log("Text copied to clipboard");
      } else {
        showLink = true;
        console.warn("Clipboard API not supported");
      }
    }

    openSnackbar({
      open: true,
      message: isSuccessful
        ? showLink
          ? `Please send the password reset link to the user:\n${resetPwLink}`
          : "Reset password link copied to clipboard"
        : "Reset password attempt failed",
      variant: "alert",
      alert: {
        color: isSuccessful ? "success" : "error",
      },
      close: false,
    });
  };

  useEffect(() => {
    getUsers();
  }, [refetch]);

  useEffect(() => {
    getVendors();
  }, []);

  /* For testing purposes using dataTest */
  // useEffect(() => {
  //   if (!_.isEmpty(data)) {
  //     setAllVendors(data.map((device) => device.vendor));
  //   }
  // }, [data]);

  const columns = useMemo(() => {
    return [
      {
        header: "Actions",
        size: 175,
        enableHiding: false,
        sticky: "left",
        cell: ({ row }) => {
          const user = row.original;
          return ActionCell({
            row,
            theme,
            handleEdit: () => {
              setEditingUser(user);
              setShowEditUserDialog(true);
            },
            handleDelete: () => {
              setUserToDelete(user);
              setShowDeleteUserDialog(true);
            },
            extrasArr: isSystemAdmin
              ? [
                  {
                    tooltipMsg: "Reset password",
                    icon: <LockReset />,
                    handleClick: () => handleResetPassword(user),
                  },
                ]
              : undefined,
          });
        },
      },
      {
        header: "Name",
        size: 225,
        minSize: 200,
        enableHiding: false,
        accessorKey: "name.full",
        // filterFn: fuzzyFilter,
        filterFn: "includesString",
      },
      {
        header: "Email",
        size: 225,
        minSize: 200,
        accessorKey: "email",
        // filterFn: fuzzyFilter,
        filterFn: "includesString",
      },
      {
        header: "Vendor",
        size: 225,
        minSize: 200,
        accessorKey: "vendor.name",
        // filterFn: fuzzyFilter,
        filterFn: "includesString",
      },
      {
        header: "Status",
        size: 150,
        minSize: 125,
        accessorFn: (row) =>
          _.get(row, "disabled", false) ? "Disabled" : "Enabled",
        isEnum: true,
        filterFn: enumFilter,
      },
      {
        header: "Roles",
        size: 350,
        minSize: 325,
        accessorFn: (row) => _.get(row, "roles", []).join(","),
        cell: ({ row }) => {
          const roles = _.get(row, "original.roles", []);

          return (
            <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
              {roles.map((role) => (
                <Chip label={role} key={role} />
              ))}
            </Stack>
          );
        },
        filterFn: "includesString",
        sortingFn: (rowA, rowB) => arraySort(rowA, rowB, "roles"),
      },
    ];
  }, [isSystemAdmin]);

  return (
    <>
      <Grid
        className="w-full overflow-hidden rounded-lg"
        style={{
          margin: theme.spacing(2),
          padding: theme.spacing(3),
          backgroundColor: colors.grey[100],
        }}
      >
        <Typography variant="h4">Users</Typography>
        <Divider
          style={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
          }}
        />
        <LocalTable name="users-table" data={data} columns={columns}>
          {isSystemAdmin && (
            <Button
              variant="contained"
              onClick={() => {
                setEditingUser();
                setShowEditUserDialog(true);
              }}
              disabled={loading}
              sx={{ width: "max-content" }}
            >
              <Add style={{ marginRight: theme.spacing(1) }} />
              Create User
            </Button>
          )}
        </LocalTable>
      </Grid>
      <DeleteDialog
        open={showDeleteUserDialog}
        handleClose={() => setShowDeleteUserDialog(false)}
        handleDelete={handleDeleteUser}
        text={`Are you sure you want to delete user, ${_.get(
          userToDelete,
          "name.full",
          ""
        )}?`}
      />
      <CreateEditUserDialog
        open={showEditUserDialog}
        user={editingUser}
        setOpen={setShowEditUserDialog}
        handleSubmit={editingUser ? handleEditUser : handleCreateUser}
        vendorOptions={allVendors}
      />
    </>
  );
};

export default Users;
