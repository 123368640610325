import React, { createContext, useReducer } from "react";
import PropTypes from "prop-types";

const actions = {
  OPEN_SNACKBAR: "OPEN_SNACKBAR",
  CLOSE_SNACKBAR: "CLOSE_SNACKBAR",
  HANDLER_INCREASE: "HANDLER_INCREASE",
  HANDLER_DENSE: "HANDLER_DENSE",
  HANDLER_ICON_VARIANTS: "HANDLER_ICON_VARIANTS",
};

const initialState = {
  action: false,
  open: false,
  message: "Note archived",
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  variant: "default",
  alert: {
    color: "primary",
    variant: "filled",
  },
  transition: "Fade",
  close: true,
  actionButton: false,
  maxStack: 3,
  dense: false,
  iconVariant: "usedefault",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.OPEN_SNACKBAR:
      const {
        open,
        message,
        anchorOrigin,
        variant,
        alert,
        transition,
        close,
        actionButton,
      } = action.payload;
      return {
        ...state,
        action: !action,
        open: open || initialState.open,
        message: message || initialState.message,
        anchorOrigin: anchorOrigin || initialState.anchorOrigin,
        variant: variant || initialState.variant,
        alert: {
          color: alert?.color || initialState.alert.color,
          variant: alert?.variant || initialState.alert.variant,
        },
        transition: transition || initialState.transition,
        close: close === false ? close : initialState.close,
        actionButton: actionButton || initialState.actionButton,
      };
    case actions.CLOSE_SNACKBAR:
      return {
        ...state,
        open: false,
      };
    case actions.HANDLER_INCREASE:
      return {
        ...state,
        maxStack: action.maxStack,
      };
    case actions.HANDLER_DENSE:
      return {
        ...state,
        dense: action.dense,
      };
    case actions.HANDLER_ICON_VARIANTS:
      return {
        ...state,
        iconVariant: action.iconVariant,
      };
    default:
      return state;
  }
};

//Context and Provider
const SnackbarContext = createContext();

const SnackbarProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    snackbar: state,
    openSnackbar(payload) {
      dispatch({ type: actions.OPEN_SNACKBAR, payload });
    },
    closeSnackbar() {
      dispatch({ type: actions.CLOSE_SNACKBAR });
    },
    handlerIncrease(maxStack) {
      dispatch({ type: actions.HANDLER_INCREASE, maxStack });
    },
    handlerDense(dense) {
      dispatch({ type: actions.HANDLER_DENSE, dense });
    },
    handlerIconVariants(iconVariant) {
      dispatch({ type: actions.HANDLER_ICON_VARIANTS, iconVariant });
    },
  };

  return (
    <SnackbarContext.Provider value={value}>
      {children}
    </SnackbarContext.Provider>
  );
};

SnackbarProvider.propTypes = {
  children: PropTypes.node,
};

export { SnackbarProvider, SnackbarContext };
