import PropTypes from "prop-types";

// material-ui
import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
  Avatar,
} from "@mui/material";

import { Delete } from "@mui/icons-material";
import { FadeTransition } from "./controls";

// ==============================|| ACTION - DELETE ||============================== //

export default function DeleteDialog({
  open,
  handleClose,
  handleDelete,
  text,
  disabled = false,
  submitButtonText,
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted
      TransitionComponent={FadeTransition}
      maxWidth="xs"
    >
      <DialogContent sx={{ mt: 2, my: 1 }}>
        <Stack alignItems="center" spacing={3.5}>
          <Avatar
            color="error"
            sx={{ width: 72, height: 72, fontSize: "1.75rem" }}
          >
            <Delete />
          </Avatar>
          <Stack spacing={2}>
            <Typography variant="h5" align="center">
              {text || "Are you sure you want to delete?"}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2} sx={{ width: 1 }}>
            <Button
              fullWidth
              onClick={() => handleClose()}
              color="secondary"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              fullWidth
              color="error"
              variant="contained"
              disabled={disabled}
              onClick={() => {
                handleDelete();
              }}
              autoFocus
            >
              {submitButtonText || "Delete"}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

DeleteDialog.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};
