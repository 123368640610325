import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Autocomplete,
  Popper,
  autocompleteClasses,
  ListItem,
  useMediaQuery,
} from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import _ from "lodash";

// Adapter for react-window and react-window-infinite-loader
const ListboxComponent = forwardRef(function ListboxComponent(props, ref) {
  const {
    children,
    hasMoreData,
    isMoreDataLoading,
    loadMoreData,
    getOptionLabel,
    rowLimit,
    id, // use for debugging purposes
    ...other
  } = props;

  const items = [];
  children.forEach((item) => {
    items.push(item);
    items.push(...(item.children || []));
  });

  const theme = useTheme();

  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasMoreData ? items.length + 1 : items.length;

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isMoreDataLoading ? () => {} : loadMoreData;

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index) => !hasMoreData || index < items.length;

  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const itemSize = smUp ? 36 : 48;

  const getHeight = () => {
    if (itemCount > rowLimit) {
      return rowLimit * itemSize;
    }
    return items.map((el) => itemSize).reduce((a, b) => a + b, 0);
  };

  // Render an item or a loading indicator.
  const Item = ({ index, style }) => {
    const dataSet = items[index];

    let content;
    if (!isItemLoaded(index)) {
      content = "Loading...";
    } else {
      const option = _.get(dataSet, ["1"], {});
      content = _.isNil(getOptionLabel) ? option : getOptionLabel(option);
    }

    return (
      <ListItem
        {..._.get(dataSet, ["0"], {})}
        onClick={_.get(dataSet, ["0"], {}).onClick}
        key={index}
        style={style}
      >
        <Typography
          noWrap
          style={{
            width: "100%",
            textAlign: "start",
          }}
        >
          {content}
        </Typography>
      </ListItem>
    );
  };

  return (
    <div ref={ref} {...other}>
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        itemCount={itemCount}
        loadMoreItems={loadMoreItems}
      >
        {({ onItemsRendered, ref }) => (
          <FixedSizeList
            className="List"
            height={getHeight()}
            itemCount={itemCount}
            itemSize={itemSize}
            innerElementType="ul"
            outerElementType="div"
            onItemsRendered={onItemsRendered}
            ref={ref}
          >
            {Item}
          </FixedSizeList>
        )}
      </InfiniteLoader>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

export default function VirtualizedAutocompleteLazy({
  id,
  getOptionLabel,
  handleSearch = () => {},
  handleClear = () => {},
  hasMoreData,
  isMoreDataLoading,
  loadMoreData,
  rowLimit,
  ...props
}) {
  return (
    <Autocomplete
      {...props}
      id={id}
      getOptionLabel={getOptionLabel}
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      ListboxProps={{
        hasMoreData,
        isMoreDataLoading,
        loadMoreData,
        getOptionLabel,
        rowLimit,
        id,
      }}
      renderOption={(props, option, state) => [props, option, state.index]}
      onInputChange={(e, val, reason) => {
        if (reason === "clear") {
          handleClear();
        } else {
          handleSearch(val);
        }
      }}
    />
  );
}
