import * as React from "react";
import _ from "lodash";

import { useTheme } from "@mui/material/styles";

import {
  IconButton,
  Typography,
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { FadeTransition } from "./controls";

import {
  EditOutlined,
  AddCircleOutlined,
  CloseOutlined,
} from "@mui/icons-material";

const ICONS = {
  edit: <EditOutlined />,
  add: <AddCircleOutlined />,
};

function DefaultDialog({
  open,
  title,
  maxWidth = "sm",
  disableClose,
  disableConfirm,
  handleClose,
  closeButtonName,
  handleConfirm,
  confirmButtonName,
  children = null,
  iconType = "edit",
  errorMessage = "",
  leftConfirm = false,
  fullWidth = true,
  fullScreen = false,
  disableEnforceFocus = false,
  hideDialogActions = false,
  sx = {},
  paperSx = {},
  backdropSx = {},
  handleOnClickX,
  hideCloseButtons = false,
  numEquipment = null,
  handleDelete,
  dialogContentStyle,
}) {
  const theme = useTheme();

  return (
    <Dialog
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      disableEnforceFocus={disableEnforceFocus}
      keepMounted
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        handleClose();
      }}
      maxWidth={maxWidth}
      TransitionComponent={FadeTransition}
      sx={{
        "& .MuiDialog-paper": { p: 0 },
        transition: "transform 225ms",
        ...sx,
      }}
      PaperProps={{ sx: { ...paperSx } }}
      slotProps={{
        backdrop: {
          sx: {
            ...backdropSx,
          },
        },
      }}
    >
      <DialogTitle
        style={{
          backgroundColor: theme.palette.primary.main,
          color: "white",
          fontSize: "18px",
          height: "60px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(1),
          }}
        >
          {ICONS[iconType]}
          <span>{title}</span>
        </div>

        {!hideCloseButtons && (
          <IconButton
            onClick={handleOnClickX ? handleOnClickX : handleClose}
            style={{ color: "white" }}
          >
            <CloseOutlined />
          </IconButton>
        )}
      </DialogTitle>
      {children && (
        <DialogContent
          style={{
            paddingTop: "20px",
            scrollbarWidth: "thin",
            ...dialogContentStyle,
          }}
        >
          {children}
        </DialogContent>
      )}
      {!hideDialogActions && (
        <DialogActions
          style={
            leftConfirm || !_.isNil(numEquipment) || handleDelete
              ? {
                  justifyContent: "space-between",
                  paddingTop: 12,
                  paddingBottom: 12,
                  paddingLeft: 25,
                  paddingRight: 23,
                }
              : {}
          }
        >
          {handleDelete && (
            <Button onClick={handleDelete} variant="contained" color="error">
              Delete
            </Button>
          )}
          {handleConfirm && leftConfirm && (
            <Button
              onClick={handleConfirm}
              variant="contained"
              disabled={disableConfirm}
            >
              {confirmButtonName || "Confirm"}
            </Button>
          )}
          {errorMessage && (
            <Typography variant="h6" color="crimson">
              {errorMessage}
            </Typography>
          )}
          <Stack direction="row" spacing={1}>
            {!hideCloseButtons && (
              <Button
                disabled={disableClose}
                color="error"
                onClick={handleClose}
              >
                {closeButtonName || "Close"}
              </Button>
            )}
            {handleConfirm && !leftConfirm && (
              <Button
                onClick={handleConfirm}
                variant="contained"
                disabled={disableConfirm}
              >
                {confirmButtonName || "Confirm"}
              </Button>
            )}
          </Stack>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default DefaultDialog;
