import moment from "moment-timezone";
import _ from "lodash";
import { EMAIL_REGEX } from "../constants";

const timezone = process.env.REACT_APP_TIMEZONE;

export const formatDate = (date, format) => {
  return moment(date).tz(timezone).format(format);
};

export const titleCase = (str) => {
  return _.startCase(_.lowerCase(str));
};

export function isValidEmail(email) {
  if (!email) return false;
  return email.toLowerCase().match(EMAIL_REGEX);
}

export function isValidPhone(phone) {
  if (!phone) return false;
  let trimmed = trimNonDigit(phone);
  return trimmed.toString().length === 10;
}

export function trimNonDigit(text) {
  return text.replace(/\D/g, "");
}

export function reverseArrDict(dict) {
  const reversed = {};

  Object.entries(dict).forEach(([key, values]) => {
    values.forEach((value) => {
      if (!reversed[value]) {
        reversed[value] = [];
      }
      reversed[value].push(key);
    });
  });

  return reversed;
}

export function trimStringsInObject(obj) {
  let objClone = _.cloneDeep(obj);

  _.forEach(objClone, (value, key) => {
    if (_.isString(value)) {
      objClone[key] = value.trim();
    } else if (_.isObject(value) && !_.isArray(value)) {
      trimStringsInObject(value);
    } else if (_.isArray(value)) {
      objClone[key] = value.map((item) => {
        if (_.isString(item)) {
          return item.trim();
        } else if (_.isObject(item)) {
          return trimStringsInObject(item);
        } else {
          return item;
        }
      });
    }
  });

  return objClone;
}

export function hasRoleAccess(userRoles, accessRole) {
  const accessDict = {
    "System Administrator": 3,
    "Vendor Administrator": 2,
    "Vendor Employee": 1,
  };

  const maxUserRole = _.max(
    userRoles.map((role) => _.get(accessDict, role, 0))
  );
  return maxUserRole >= _.get(accessDict, accessRole, 4);
}

export function camelCaseToSpaceCase(str) {
  return _.startCase(str).toLowerCase();
}

export function generateRandomString(length = 16) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  return _.times(length, () => _.sample(characters)).join("");
}

export function areArrsEqualExclOrder(arr1 = [], arr2 = []) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = _.sortBy(arr1);
  const sortedArr2 = _.sortBy(arr2);

  return _.isEqual(sortedArr1, sortedArr2);
}
