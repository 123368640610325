import React, { useState, useEffect } from "react";
import _ from "lodash";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";
import InputMask from "react-input-mask";

import { TextField, Grid, Stack, Switch, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import DefaultDialog from "../Dialogs/DefaultDialog";
import { Section, TagInput } from "../Dialogs/controls";

import { trimNonDigit, isValidPhone, isValidEmail } from "../../utils/misc";

const CreateEditLocationDialog = ({
  open,
  setOpen,
  location,
  handleSubmit,
}) => {
  const theme = useTheme();

  const textfieldStyles = { height: "1.2rem" };

  const isCreate = _.isNil(location);

  const schema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    address: Yup.string().required("Street is required"),
    city: Yup.string().required("City is required"),
    collectorCode: Yup.string().required("Collector code is required"),
    province: Yup.string(),
    country: Yup.string(),
    contact: Yup.string(),
    contactPhone: Yup.string().test(
      "validPhoneNumber",
      "Invalid phone number ",
      (value) => {
        if (!value || _.isEmpty(trimNonDigit(value))) return true;
        return isValidPhone(value);
      }
    ),
    contactEmail: Yup.string()
      .email("Invalid email")
      .test("validEmail", "Invalid email", (value) => {
        if (!value) return true;
        return isValidEmail(value);
      }),
    tags: Yup.array(),
    isRetail: Yup.boolean(),
  });

  const getInitialValues = (location) => {
    let initVals = {
      name: "",
      address: "",
      city: "",
      collectorCode: "",
      province: "",
      country: "",
      contact: "",
      contactPhone: "",
      contactEmail: "",
      tags: [],
      isRetail: false,
    };

    if (!_.isNil(location)) {
      initVals = _.merge({}, initVals, location);
    }

    return initVals;
  };

  const formik = useFormik({
    initialValues: getInitialValues(location),
    validationSchema: schema,
    onSubmit: (values, { setSubmitting }) => {
      try {
        handleSubmit(values);
        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    },
    enableReinitialize: true,
  });

  const {
    values,
    errors,
    touched,
    handleSubmit: handleSubmitFormik,
    isSubmitting,
    setFieldValue,
    getFieldProps,
    resetForm,
  } = formik;

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  return (
    <DefaultDialog
      open={open}
      title={`${isCreate ? "Create" : "Edit"} Location`}
      iconType={isCreate ? "add" : "edit"}
      handleClose={() => setOpen(false)}
      handleConfirm={handleSubmitFormik}
      confirmButtonName={isCreate ? "Create" : "Update"}
      closeButtonName="Cancel"
      disableConfirm={isSubmitting}
    >
      <FormikProvider value={formik}>
        <Stack spacing={2}>
          <Section title="General" theme={theme}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  {...getFieldProps("name")}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  label="Name"
                  InputLabelProps={{
                    style: textfieldStyles,
                    shrink: !_.isEmpty(values.name),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  {...getFieldProps("collectorCode")}
                  error={Boolean(touched.collectorCode && errors.collectorCode)}
                  helperText={touched.collectorCode && errors.collectorCode}
                  label="Collector Code"
                  InputLabelProps={{
                    style: textfieldStyles,
                    shrink: !_.isEmpty(values.collectorCode),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TagInput
                  value={values.tags}
                  setValue={(tags) => setFieldValue("tags", tags)}
                  reset={open}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Switch
                    checked={values.isRetail}
                    onChange={(event) =>
                      setFieldValue("isRetail", event.target.checked)
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <Typography>Retail Location</Typography>
                </Stack>
              </Grid>
            </Grid>
          </Section>

          <Section title="Address" theme={theme}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  {...getFieldProps("address")}
                  error={Boolean(touched.address && errors.address)}
                  helperText={touched.address && errors.address}
                  label="Street"
                  InputLabelProps={{
                    style: textfieldStyles,
                    shrink: !_.isEmpty(values.address),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  fullWidth
                  {...getFieldProps("city")}
                  error={Boolean(touched.city && errors.city)}
                  helperText={touched.city && errors.city}
                  label="City"
                  InputLabelProps={{
                    style: textfieldStyles,
                    shrink: !_.isEmpty(values.city),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  {...getFieldProps("province")}
                  error={Boolean(touched.province && errors.province)}
                  helperText={touched.province && errors.province}
                  label="Province/State"
                  InputLabelProps={{
                    style: textfieldStyles,
                    shrink: !_.isEmpty(values.province),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  {...getFieldProps("country")}
                  error={Boolean(touched.country && errors.country)}
                  helperText={touched.country && errors.country}
                  label="Country"
                  InputLabelProps={{
                    style: textfieldStyles,
                    shrink: !_.isEmpty(values.country),
                  }}
                />
              </Grid>
            </Grid>
          </Section>

          <Section title="Contact" theme={theme}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  {...getFieldProps("contact")}
                  error={Boolean(touched.contact && errors.contact)}
                  helperText={touched.contact && errors.contact}
                  label="Name"
                  InputLabelProps={{
                    style: textfieldStyles,
                    shrink: !_.isEmpty(values.contact),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputMask
                  mask="(999) 999-9999"
                  {...getFieldProps("contactPhone")}
                  onChange={(e) => {
                    setFieldValue("contactPhone", trimNonDigit(e.target.value));
                  }}
                >
                  {() => {
                    return (
                      <TextField
                        fullWidth
                        label="Phone"
                        error={Boolean(
                          touched.contactPhone && errors.contactPhone
                        )}
                        helperText={touched.contactPhone && errors.contactPhone}
                        InputLabelProps={{
                          style: textfieldStyles,
                          shrink: !_.isEmpty(values.contactPhone),
                        }}
                      />
                    );
                  }}
                </InputMask>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  {...getFieldProps("contactEmail")}
                  error={Boolean(touched.contactEmail && errors.contactEmail)}
                  helperText={touched.contactEmail && errors.contactEmail}
                  label="Email"
                  InputLabelProps={{
                    style: textfieldStyles,
                    shrink: !_.isEmpty(values.contactEmail),
                  }}
                />
              </Grid>
            </Grid>
          </Section>
        </Stack>
      </FormikProvider>
    </DefaultDialog>
  );
};

export default CreateEditLocationDialog;
