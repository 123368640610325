import { colors } from "@mui/material";
import { createTheme } from "@mui/material/styles";

let envColor, primaryColor, secondaryColor;
switch (process.env.REACT_APP_ENV) {
  case "PROD":
    envColor = colors.blue;
    primaryColor = colors.blue;
    secondaryColor = colors.pink;
    break;
  case "TEST":
    envColor = colors.deepOrange;
    primaryColor = colors.blue;
    secondaryColor = colors.pink;

    break;
  case "LOCAL":
    envColor = colors.blue;
    primaryColor = colors.blue;
    secondaryColor = colors.pink;

    break;
  default:
    throw new Error("Error occurred while loading themes (Check ENV)");
}

const PRIMARY_COLOR = primaryColor;
const SECONDARY_COLOR = secondaryColor;

export const lightTheme = (rootElement) =>
  createTheme({
    palette: {
      type: "light",
      primary: PRIMARY_COLOR,
      secondary: SECONDARY_COLOR,
      envColor,
      errorTextColor: colors.red[500],
    },
    spacing: (factor) => factor * 8,
    components: {
      MuiPopover: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiPopper: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiDialog: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiModal: {
        defaultProps: {
          container: rootElement,
        },
      },
    },
  });

export const darkTheme = (rootElement) =>
  createTheme({
    palette: {
      type: "dark",
      primary: PRIMARY_COLOR,
      secondary: SECONDARY_COLOR,
      linkColor: PRIMARY_COLOR[300],
      envColor,
    },
    spacing: (factor) => factor * 8,
    components: {
      MuiPopover: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiPopper: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiDialog: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiModal: {
        defaultProps: {
          container: rootElement,
        },
      },
    },
  });
