import _ from "lodash";
import { rankItem, compareItems } from "@tanstack/match-sorter-utils";
import { sortingFns } from "@tanstack/react-table";

// Define a custom fuzzy filter function that will apply ranking info to rows (using match-sorter utils)
export const fuzzyFilter = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

// Define a custom fuzzy sort function that will sort by rank if the row has ranking information
export const fuzzySort = (rowA, rowB, columnId) => {
  let dir = 0;

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank,
      rowB.columnFiltersMeta[columnId]?.itemRank
    );
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

export const enumFilter = (row, col, filterVal) => {
  const colVal = row.getValue(col).toString();
  if (_.isEmpty(filterVal)) return true;
  else return filterVal.includes(colVal);
};

export const getSortVal = (valA, valB) => {
  if (valA < valB) {
    return -1;
  } else if (valA > valB) {
    return 1;
  } else {
    return 0;
  }
};

export const arraySort = (rowA, rowB, key) => {
  const countA = _.get(rowA, ["original", key], []).length;
  const countB = _.get(rowB, ["original", key], []).length;

  return getSortVal(countA, countB);
};

export const getDeviceName = (device) => {
  if (_.isEmpty(device)) return "";

  const { serialNumber, type } = device;
  const isUnknown = type === "unknown";
  return isUnknown
    ? `${serialNumber}`
    : `${serialNumber} (${_.upperCase(type)})`;
};
