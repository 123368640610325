import React, { useState, useEffect } from "react";
import _ from "lodash";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";
import { useTheme } from "@mui/material/styles";

import {
  Box,
  Button,
  IconButton,
  Input,
  Typography,
  TextField,
  Grid,
  Autocomplete,
  MenuItem,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "@mui/material";
import {
  AddCircle,
  Check,
  Close,
  Delete,
  Edit,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";

import DefaultDialog from "../Dialogs/DefaultDialog";
import { DEVICE_TYPES } from "../../constants";

const CreateEditVendorDialog = ({
  open,
  setOpen,
  vendor,
  depositGroups,
  handleSubmit,
  locationOptions = [],
}) => {
  const theme = useTheme();

  const isCreate = _.isNil(vendor);

  const [isOpen, setIsOpen] = useState(true);
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const [mappings, setMappings] = useState([]);

  const [newMapping, setNewMapping] = useState({});

  const handleAddNewMapping = () => {
    const updatedMappings = _.cloneDeep(mappings);
    const mappingToAdd = _.cloneDeep(newMapping);

    updatedMappings.push(mappingToAdd);

    setMappings(updatedMappings);
    setNewMapping({});
  };

  const handleEditNewMapping = (e) => {
    const field = e.target.name;
    const value = e.target.value;

    const updatedItem = _.cloneDeep(newMapping);

    updatedItem[field] = value;

    setNewMapping(updatedItem);
  };

  const [mappingEditIdx, setMappingEditIdx] = useState(-1);
  const [editMapping, setEditMapping] = useState({});

  const handleOnEditMapping = (i) => {
    const oldMappings = _.cloneDeep(mappings);
    const oldMapping = oldMappings[i];

    setMappingEditIdx(i);
    setEditMapping(oldMapping);
  };

  const handleEditMapping = (e) => {
    const field = e.target.name;
    const value = e.target.value;

    const updatedMapping = _.cloneDeep(editMapping);
    updatedMapping[field] = value;

    setEditMapping(updatedMapping);
  };

  const handleSaveMapping = (i) => {
    const updatedMappings = _.cloneDeep(mappings);
    updatedMappings[i] = _.cloneDeep(editMapping);

    setMappings(updatedMappings);

    setEditMapping({});
    setMappingEditIdx(-1);
  };

  const handleDeleteMapping = (i) => {
    const updatedMappings = _.cloneDeep(mappings);
    updatedMappings.splice(i, 1);

    setMappings(updatedMappings);

    setEditMapping({});
    setMappingEditIdx(-1);
  };

  const schema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    deviceTypes: Yup.array().min(
      1,
      "At least one allowed device type is required"
    ),
    allowedLocations: Yup.array(),
  });

  const getInitialValues = (vendor) => {
    let initVals = {
      name: "",
      deviceTypes: [],
      allowedLocations: [],
    };

    if (!_.isNil(vendor)) {
      initVals = _.merge({}, initVals, vendor);
    }

    return initVals;
  };

  const formik = useFormik({
    initialValues: getInitialValues(vendor),
    validationSchema: schema,
    onSubmit: (values, { setSubmitting }) => {
      try {
        const vendorToSubmit = _.cloneDeep(values);
        const { allowedLocations } = vendorToSubmit;

        _.set(
          vendorToSubmit,
          "allowedLocations",
          allowedLocations.map((loc) => loc._id)
        );

        _.set(vendorToSubmit, "depositMapping", mappings);
        handleSubmit(vendorToSubmit);
        setSubmitting(false);
      } catch (error) {
        // console.error(error);
      }
    },
    enableReinitialize: true,
  });

  const isEmptyMapping = (mapping) => {
    if (_.isEmpty(mapping)) return true;
    if (_.isEmpty(mapping.materialId)) return true;
    if (_.isNaN(mapping.deposit)) return true;
    if (_.isEmpty(mapping.depositGroup)) return true;

    return false;
  };

  const {
    values,
    errors,
    touched,
    handleSubmit: handleSubmitFormik,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    resetForm,
  } = formik;

  useEffect(() => {
    if (!open) {
      resetForm();
    } else {
      // reset deposit mappings on reopen of dialog
      const vendorDepositMappings = _.get(vendor, "depositMapping", []);
      for (let mapping of vendorDepositMappings) {
        mapping.depositGroup = _.get(
          mapping,
          "depositGroup._id",
          mapping.depositGroup
        );
      }

      setMappings(vendorDepositMappings);

      setNewMapping({});
      setEditMapping({});
      setMappingEditIdx(-1);
    }
  }, [open]);

  return (
    <DefaultDialog
      open={open}
      title={`${isCreate ? "Create" : "Edit"} Vendor`}
      iconType={isCreate ? "add" : "edit"}
      handleClose={() => setOpen(false)}
      handleConfirm={handleSubmitFormik}
      confirmButtonName={isCreate ? "Create" : "Update"}
      closeButtonName="Cancel"
      disableConfirm={isSubmitting}
    >
      <FormikProvider value={formik}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              {...getFieldProps("name")}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
              label="Name"
              InputLabelProps={{
                style: {
                  height: "1.2em",
                },
                shrink: !_.isEmpty(values.name),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              filterSelectedOptions
              disableCloseOnSelect
              value={values.deviceTypes}
              getOptionLabel={(option) => _.upperCase(option)}
              onChange={(event, value) => setFieldValue("deviceTypes", value)}
              options={DEVICE_TYPES}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Allowed Device Types"
                  error={Boolean(touched.deviceTypes && errors.deviceTypes)}
                  helperText={touched.deviceTypes && errors.deviceTypes}
                  InputLabelProps={{
                    style: {
                      height: "1.2em",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              filterSelectedOptions
              disableCloseOnSelect
              value={values.allowedLocations}
              getOptionLabel={(location) =>
                `${location.name} (${location.collectorCode})`
              }
              onChange={(event, value) =>
                setFieldValue("allowedLocations", value)
              }
              options={locationOptions}
              isOptionEqualToValue={(option, value) =>
                _.get(option, "_id", 1) === _.get(value, "_id", 2)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Locations"
                  InputLabelProps={{
                    style: {
                      height: "1.2em",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                border: "1px solid #ccc",
                borderRadius: "0.25rem",
                padding: "6px 14px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography style={{ color: theme.palette.text.secondary }}>
                  Vendor Mappings
                </Typography>
                <IconButton
                  onClick={handleToggle}
                  // style={{ marginRight: "-12px" }}
                >
                  {isOpen ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </div>
              {isOpen && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: theme.spacing(0.5),
                  }}
                >
                  <Table sx={{ width: "100%", tableLayout: "fixed" }}>
                    <TableHead>
                      <TableCell>Material ID *</TableCell>
                      <TableCell sx={{ width: "20%" }}>
                        Deposit (cents)
                      </TableCell>
                      <TableCell sx={{ width: "30%" }}>Group</TableCell>
                      <TableCell />
                    </TableHead>
                    <TableBody>
                      {mappings.map((data, i) => {
                        if (i == mappingEditIdx) {
                          return (
                            <TableRow>
                              <TableCell>
                                <TextField
                                  fullWidth
                                  label="Material ID"
                                  size="small"
                                  variant="standard"
                                  name="materialId"
                                  value={editMapping.materialId}
                                  onChange={handleEditMapping}
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  fullWidth
                                  label="Deposit"
                                  size="small"
                                  variant="standard"
                                  name="deposit"
                                  value={editMapping.deposit}
                                  onChange={handleEditMapping}
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  select
                                  fullWidth
                                  label="Group"
                                  size="small"
                                  variant="standard"
                                  name="depositGroup"
                                  value={editMapping.depositGroup}
                                  onChange={handleEditMapping}
                                >
                                  {depositGroups.map((depositGroup) => (
                                    <MenuItem
                                      key={depositGroup.name}
                                      value={depositGroup._id}
                                    >
                                      {depositGroup.name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </TableCell>
                              <TableCell style={{ display: "flex" }}>
                                <IconButton
                                  disabled={isEmptyMapping(editMapping)}
                                  onClick={() => handleSaveMapping(i)}
                                >
                                  <Check />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    setMappingEditIdx(-1);
                                  }}
                                >
                                  <Close />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        }

                        const depositGroup = _.find(
                          depositGroups,
                          (group) => group._id == data.depositGroup
                        );

                        return (
                          <TableRow key={`mapping-${i}`}>
                            <TableCell>{data.materialId}</TableCell>
                            <TableCell>{data.deposit}</TableCell>
                            <TableCell>
                              {_.get(depositGroup, "name", "")}
                            </TableCell>
                            <TableCell style={{ display: "flex" }}>
                              <IconButton
                                onClick={() => handleOnEditMapping(i)}
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                onClick={() => handleDeleteMapping(i)}
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      <TableRow>
                        <TableCell>
                          <TextField
                            fullWidth
                            label="Material ID"
                            size="small"
                            variant="standard"
                            name="materialId"
                            value={newMapping.materialId || ""}
                            onChange={handleEditNewMapping}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            label="Deposit"
                            size="small"
                            variant="standard"
                            name="deposit"
                            value={newMapping.deposit || ""}
                            onChange={handleEditNewMapping}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            select
                            fullWidth
                            label="Group"
                            size="small"
                            variant="standard"
                            name="depositGroup"
                            value={newMapping.depositGroup || ""}
                            onChange={handleEditNewMapping}
                          >
                            {depositGroups.map((depositGroup) => (
                              <MenuItem
                                key={depositGroup.name}
                                value={depositGroup._id}
                              >
                                {depositGroup.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            disabled={isEmptyMapping(newMapping)}
                            onClick={handleAddNewMapping}
                          >
                            <AddCircle />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Typography
                    style={{
                      margin: theme.spacing(1),
                      marginTop: theme.spacing(2),
                    }}
                    variant="subtitle2"
                  >
                    * Material ID's are case sensitive. An exact match will be
                    used when comparing to incoming data.
                  </Typography>
                </div>
              )}
            </Box>
          </Grid>
        </Grid>
      </FormikProvider>
    </DefaultDialog>
  );
};

export default CreateEditVendorDialog;
