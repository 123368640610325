import React from "react";
import _ from "lodash";
import { useLocation, Navigate, Outlet, Route } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Dashboard from "../containers/Dashboard/Dashboard";
import { hasRoleAccess } from "../utils/misc";

const RequireAuth = ({ roleAccess }) => {
  const { authenticated } = useAuth();
  const location = useLocation();

  return hasRoleAccess(_.get(authenticated, "roles", []), roleAccess) ? (
    <Dashboard />
  ) : authenticated ? (
    <Navigate to="/dashboard" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
