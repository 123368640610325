import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import moment from "moment-timezone";

import useHttp from "../../hooks/useHttp";
import useLoading from "../../hooks/useLoading";
import useSnackbar from "../../hooks/useSnackbar";

import {
  Button,
  Divider,
  Grid,
  Typography,
  Stack,
  Popover,
  colors,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

import { ActionCell } from "../Table/controls";
import { getSortVal, arraySort } from "../Table/misc";

import LocalTable from "../Table/LocalTable";
import DeleteDialog from "../Dialogs/DeleteDialog";
import CreateEditOperatorDialog from "./CreateEditOperatorDialog";

import { useLocation } from "react-router-dom";

const Operators = () => {
  const theme = useTheme();

  const location = useLocation();
  const { get, post } = useHttp();
  const { loading, setLoading } = useLoading();
  const { openSnackbar } = useSnackbar();

  const [editingOperator, setEditingOperator] = useState();
  const [showEditOperatorDialog, setShowEditOperatorDialog] = useState(false);

  const [operatorToDelete, setOperatorToDelete] = useState();
  const [showDeleteOperatorDialog, setShowDeleteOperatorDialog] =
    useState(false);

  const [allVendors, setAllVendors] = useState([]);
  const [allLocations, setAllLocations] = useState([]);

  const [data, setData] = useState([]);
  const [refetch, setRefetch] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverWidth, setPopoverWidth] = useState(100);
  const [popoverContent, setPopoverContent] = useState();

  const searchParams = location.search;

  const handleOpenPopover = (event) => {
    const target = event.currentTarget;
    setAnchorEl(target);
    setPopoverWidth(target.clientWidth);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const getVendors = async () => {
    setLoading(true);
    const res = await get("/getVendors");
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setAllVendors(res.data.vendors);
    }
    setLoading(false);
  };

  const getLocations = async () => {
    setLoading(true);
    const res = await get("/getLocations");
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setAllLocations(res.data.locations);
    }
    setLoading(false);
  };

  const getOperators = async () => {
    const query = new URLSearchParams(searchParams);

    setLoading(true);
    const res = await get("/getOperators", {
      global: query.get("global"),
      filter: query.get("filter"),
      sort: query.get("sort"),
      page: query.get("page"),
      limit: query.get("limit"),
    });
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setData(res.data.operatorResult);
    }
    setLoading(false);
  };

  const handleCreateOperator = async (newOperator) => {
    setLoading(true);
    const res = await post("/createOperator", { newOperator });
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setShowEditOperatorDialog(false);
      setRefetch((prev) => !prev);
    }

    openSnackbar({
      open: true,
      message: isSuccessful
        ? "Succesfully created operator"
        : "Failed to create operator",
      variant: "alert",
      alert: {
        color: isSuccessful ? "success" : "error",
      },
      close: false,
    });
    setLoading(false);
  };

  const handleEditOperator = async (newOperator) => {
    setLoading(true);
    const res = await post("/updateOperator", {
      newOperator,
      _id: editingOperator._id,
    });
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setShowEditOperatorDialog(false);
      setRefetch((prev) => !prev);
    }

    openSnackbar({
      open: true,
      message: isSuccessful
        ? "Succesfully updated operator details"
        : "Failed to update operator details",
      variant: "alert",
      alert: {
        color: isSuccessful ? "success" : "error",
      },
      close: false,
    });
    setLoading(false);
  };

  const handleDeleteOperator = async () => {
    setLoading(true);

    const res = await post("/deleteOperator", {
      _id: operatorToDelete._id,
    });
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setShowDeleteOperatorDialog(false);
      setRefetch((prev) => !prev);
    }

    openSnackbar({
      open: true,
      message: isSuccessful
        ? "Succesfully deleted operator"
        : "Failed to delete operator",
      variant: "alert",
      alert: {
        color: isSuccessful ? "success" : "error",
      },
      close: false,
    });
    setLoading(false);
  };

  useEffect(() => {
    getOperators();
  }, [refetch, searchParams]);

  useEffect(() => {
    getVendors();
    getLocations();
  }, []);

  const columns = useMemo(() => {
    return [
      {
        header: "Actions",
        size: 50,
        enableHiding: false,
        sticky: "left",
        cell: ({ row }) => {
          const operator = row.original;

          return ActionCell({
            row,
            theme,
            handleEdit: () => {
              setEditingOperator(operator);
              setShowEditOperatorDialog(true);
            },
            handleDelete: () => {
              setOperatorToDelete(operator);
              setShowDeleteOperatorDialog(true);
            },
          });
        },
      },
      {
        id: "name",
        header: "Name",
        size: 175,
        minSize: 150,
        accessorKey: "name.full",
        enableHiding: false,
        filterFn: "includesString",
        sortDescFirst: false,
      },
      {
        header: "Role",
        size: 175,
        minSize: 150,
        accessorKey: "role",
        filterFn: "includesString",
        sortDescFirst: false,
        isEnum: true,
      },
      {
        id: "vendor",
        header: "Vendor",
        size: 175,
        minSize: 150,
        accessorKey: "vendor.name",
        filterFn: "includesString",
        sortDescFirst: false,
      },
      {
        id: "locations",
        header: "Locations",
        size: 225,
        minSize: 200,
        accessorFn: (row) =>
          _.get(row, "locations", [])
            .map((location) => _.get(location, "name"))
            .join(","),
        cell: ({ row }) => {
          // will probably need the locations' ID for links so can't just use accessor values
          const locations = _.get(row, "original.locations", []);
          const locationCount = locations.length;

          const popoverLocations = _.sortBy(locations, ["name"]).map(
            (location) => (
              <Typography key={location._id} variant="body2" sx={{ p: 1 }}>
                {`${location.name} (${location.collectorCode})`}
              </Typography>
            )
          );

          return (
            <Button
              onClick={(e) => {
                handleOpenPopover(e);
                setPopoverContent(popoverLocations);
              }}
              className="w-full text-center"
              variant="outlined"
              disabled={!locationCount}
            >
              {locationCount} location{locationCount !== 1 && "s"}
            </Button>
          );
        },
        sortingFn: (rowA, rowB) => arraySort(rowA, rowB, "locations"),
        sortDescFirst: false,
        filterFn: "includesString",
      },
    ];
  }, []);

  const popoverOpen = Boolean(anchorEl);

  return (
    <>
      <Grid
        className="w-full overflow-hidden rounded-lg"
        style={{
          margin: theme.spacing(2),
          padding: theme.spacing(3),
          backgroundColor: colors.grey[100],
        }}
      >
        <Typography variant="h4">Operators</Typography>
        <Divider
          style={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
          }}
        />
        <LocalTable
          name="operators-table"
          data={_.get(data, "operators", [])}
          columns={columns}
          manualPagination={_.omit(data, ["operators"])}
        >
          <Button
            variant="contained"
            onClick={() => {
              setEditingOperator();
              setShowEditOperatorDialog(true);
            }}
            disabled={loading}
            sx={{ width: "max-content" }}
          >
            <Add style={{ marginRight: theme.spacing(1) }} />
            Create Operator
          </Button>
        </LocalTable>
      </Grid>
      <DeleteDialog
        open={showDeleteOperatorDialog}
        handleClose={() => setShowDeleteOperatorDialog(false)}
        handleDelete={handleDeleteOperator}
        text={`Are you sure you want to delete operator, ${_.get(
          operatorToDelete,
          "name.full",
          ""
        )}?`}
      />
      <CreateEditOperatorDialog
        open={showEditOperatorDialog}
        operator={editingOperator}
        setOpen={setShowEditOperatorDialog}
        handleSubmit={
          editingOperator ? handleEditOperator : handleCreateOperator
        }
        vendorOptions={allVendors}
        locationOptions={allLocations}
      />
      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Stack
          style={{
            width: `${popoverWidth}px`,
            maxHeight: "200px",
            overflow: "auto",
          }}
        >
          {popoverContent}
        </Stack>
      </Popover>
    </>
  );
};

export default Operators;
