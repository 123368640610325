import React from "react";
import _ from "lodash";

import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
  Edit,
  Delete,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

export const TablePaginationActions = ({
  count,
  page: pageIndex,
  rowsPerPage,
  onPageChange,
}) => {
  const theme = useTheme();
  const maxPageIndex = Math.ceil(count / rowsPerPage) - 1;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, pageIndex - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, pageIndex + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, maxPageIndex));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={pageIndex === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={pageIndex === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={pageIndex >= maxPageIndex}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={pageIndex >= maxPageIndex}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
};

export const ActionCell = ({
  theme,
  handleEdit,
  editIcon,
  editTooltipMsg,
  handleDelete,
  deleteIcon,
  deleteTooltipMsg,
  hideEdit = false,
  hideDelete = false,
  extrasArr = [],
}) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="center">
      {!hideEdit && (
        <Tooltip title={editTooltipMsg || "Edit"}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleEdit();
            }}
          >
            {editIcon || <Edit />}
          </IconButton>
        </Tooltip>
      )}
      {!hideDelete && (
        <Tooltip title={deleteTooltipMsg || "Delete"}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleDelete();
            }}
          >
            {deleteIcon || <Delete />}
          </IconButton>
        </Tooltip>
      )}
      {!_.isEmpty(extrasArr) &&
        extrasArr.map((extra) => (
          <Tooltip title={extra?.tooltipMsg || "Delete"}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                extra?.handleClick();
              }}
            >
              {extra?.icon || <Delete />}
            </IconButton>
          </Tooltip>
        ))}
    </Stack>
  );
};
