import React, { useEffect } from "react";
import _ from "lodash";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";
import useAuth from "../../hooks/useAuth";

import { TextField, Grid } from "@mui/material";

import DefaultDialog from "../Dialogs/DefaultDialog";

const CreateEditDepositGroupDialog = ({
  open,
  setOpen,
  depositGroup,
  handleSubmit,
}) => {
  const isCreate = _.isNil(depositGroup);

  const { authenticated } = useAuth();

  const schema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    material: Yup.string().required("Material is required"),
    value: Yup.number()
      .required("Value is required")
      .min(0, "Value must be greater than or equal to 0")
      .max(100, "Value must be less than or equal to 100"),
    superGroup: Yup.number()
      .min(0, "Value must be greater than or equal to 0")
      .max(6, "Value must be less than or equal to 6"),
  });

  const getInitialValues = (depositGroup) => {
    let initVals = {
      name: "",
      material: "",
      value: "",
      superGroup: "",
      deleted: false,
    };

    if (!_.isNil(depositGroup)) {
      initVals = _.mergeWith({}, initVals, depositGroup, (objVal, srcVal) => {
        if (_.isObject(srcVal)) {
          return _.get(srcVal, "_id");
        }
      });
    }

    return initVals;
  };

  const formik = useFormik({
    initialValues: getInitialValues(depositGroup),
    validationSchema: schema,
    onSubmit: (values, { setSubmitting }) => {
      try {
        handleSubmit(values);
        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    },
    enableReinitialize: true,
  });

  const {
    values,
    errors,
    touched,
    handleSubmit: handleSubmitFormik,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  return (
    <DefaultDialog
      open={open}
      title={`${isCreate ? "Create" : "Edit"} Deposit Group`}
      iconType={isCreate ? "add" : "edit"}
      handleClose={() => setOpen(false)}
      handleConfirm={handleSubmitFormik}
      confirmButtonName={isCreate ? "Create" : "Update"}
      closeButtonName="Cancel"
      disableConfirm={isSubmitting}
    >
      <FormikProvider value={formik}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              {...getFieldProps("name")}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
              label="Name"
              InputLabelProps={{
                style: {
                  height: "1.2em",
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              type="number"
              {...getFieldProps("superGroup")}
              error={Boolean(touched.superGroup && errors.superGroup)}
              helperText={touched.superGroup && errors.superGroup}
              label="Super Group"
              InputLabelProps={{
                style: {
                  height: "1.2em",
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              {...getFieldProps("material")}
              error={Boolean(touched.material && errors.material)}
              helperText={touched.material && errors.material}
              label="Material"
              InputLabelProps={{
                style: {
                  height: "1.2em",
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              type="number"
              {...getFieldProps("value")}
              error={Boolean(touched.value && errors.value)}
              helperText={touched.value && errors.value}
              label="Value (cents)"
              InputLabelProps={{
                style: {
                  height: "1.2em",
                },
              }}
            />
          </Grid>
        </Grid>
      </FormikProvider>
    </DefaultDialog>
  );
};

export default CreateEditDepositGroupDialog;
