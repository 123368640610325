import React, { useState } from "react";
import _ from "lodash";
import { Outlet, Route, Routes } from "react-router-dom";
import "../../index.css";

import useHttp from "../../hooks/useHttp";
import useLoading from "../../hooks/useLoading";
import useMobile from "../../hooks/useMobile";

import { useTheme } from "@mui/material/styles";
import { Grid, IconButton, Menu, MenuItem, Typography } from "@mui/material";

import {
  Menu as MenuIcon,
  Notifications,
  NotificationsActive,
  Settings,
  AccountCircle,
} from "@mui/icons-material";

import Navigation from "../Navigation/Navigation";
import LinearLoading from "../../components/Loading/LinearLoading";

function Dashboard() {
  const theme = useTheme();
  const { get } = useHttp();
  const { isMobile } = useMobile();
  const { loading } = useLoading();

  const [showNavigation, setShowNavigation] = useState(!isMobile);

  const [accountMenuAnchor, setAccountMenuAnchor] = useState(null);
  const [notificationsMenuAnchor, setNotificationsMenuAnchor] = useState(null);

  const [notifications, setNotifications] = useState([]);

  const logout = async () => {
    await get("/logout");

    localStorage.removeItem("accessToken");
    window.location.href = "/login";
  };

  return (
    <Grid
      style={{
        margin: 0,
        padding: 0,
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <LinearLoading show={loading} />
      <Grid
        style={{
          margin: 0,
          minWidth: "100%",
          padding: theme.spacing(2),
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            aria-label="toggle navigation menu"
            onClick={() => setShowNavigation(!showNavigation)}
            style={{ marginRight: theme.spacing(2) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h4">MDDS</Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <IconButton
            aria-label="toggle notifications"
            onClick={(e) => setNotificationsMenuAnchor(e.currentTarget)}
            style={{
              marginRight: theme.spacing(2),
            }}
            className={notifications.length > 0 ? "pulsating-icon" : ""}
          >
            {notifications.length > 0 ? (
              <NotificationsActive style={{ color: "white" }} />
            ) : (
              <Notifications />
            )}
          </IconButton>
          <Menu
            anchorEl={notificationsMenuAnchor}
            open={Boolean(notificationsMenuAnchor)}
            onClose={() => {
              setNotificationsMenuAnchor(null);
              setTimeout(() => {
                setNotifications([]);
              }, 100);
            }}
          >
            {notifications.length > 0 &&
              notifications.map((notification) => {
                return <MenuItem>{notification}</MenuItem>;
              })}
            {notifications.length === 0 && (
              <MenuItem>No new notifications!</MenuItem>
            )}
          </Menu> */}
          {/* <IconButton
            aria-label="toggle settings"
            onClick={(e) => {
              e.preventDefault();
            }}
            style={{ marginRight: theme.spacing(2) }}
          >
            <Settings />
          </IconButton> */}
          <IconButton
            aria-label="toggle account"
            onClick={(e) => setAccountMenuAnchor(e.currentTarget)}
            style={{ marginRight: theme.spacing(2) }}
          >
            <AccountCircle />
          </IconButton>
          <Menu
            anchorEl={accountMenuAnchor}
            open={Boolean(accountMenuAnchor)}
            onClose={() => setAccountMenuAnchor(null)}
          >
            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>
        </div>
      </Grid>
      <Grid
        style={{
          margin: 0,
          padding: 0,
          display: "flex",
          flexDirection: "row",
          minWidth: "100%",
          flex: 1,
        }}
      >
        <Navigation
          visible={showNavigation}
          handleClose={() => setShowNavigation(false)}
        />
        <Outlet />
      </Grid>
    </Grid>
  );
}

export default Dashboard;
