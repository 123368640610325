import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import React, { useState } from "react";
import { get, post } from "./utils/api";

import Pages from "./containers/Pages";
import Snackbar from "./components/Snackbar";

import { ThemeProvider } from "@mui/material";
import { lightTheme, darkTheme } from "./theme";

import { AuthProvider } from "./contexts/Auth/AuthContext";
import { HttpProvider } from "./contexts/HTTP/HttpContext";
import { MobileProvider } from "./contexts/Mobile/MobileContext";
import { LoadingProvider } from "./contexts/Loading/LoadingContext";
import { SnackbarProvider } from "./contexts/Snackbar/SnackbarContext";

function App({ rootElement }) {
  const [theme, setTheme] = useState(lightTheme(rootElement));
  const [authenticated, setAuthenticated] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   document.body.style.backgroundColor = theme.palette.background.default;
  // });

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider value={{ authenticated, setAuthenticated }}>
        <HttpProvider value={{ get, post }}>
          <MobileProvider value={{ isMobile, setIsMobile }}>
            <LoadingProvider value={{ loading, setLoading }}>
              <SnackbarProvider>
                <Pages />
                <Snackbar />
              </SnackbarProvider>
            </LoadingProvider>
          </MobileProvider>
        </HttpProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
